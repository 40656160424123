import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

// String
import { Strings } from "../../resources/i18n/i18n";

// Component
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

// Constant
import {
  DEFAULT_LOAN_STATUS,
  DEFAULT_LOAN_STATUS_SELECTION,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  STATUS_FOR_LOANS,
  LOAN_TYPE,
  CATEGORY,
  DEFAULT_LOAN_TYPE,
  DEFAULT_CATEGORY_TYPE,
  PROVISION_GRADES,
  DEFAULT_PROVISION_GRADE,
  USER_ROLES,
  DISBURSED,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  LOAN_HISTORY_LEGENDS,
} from "../../constants/appConstants";
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";

import { LOAN_HISTORY_STATUSES } from "./Constants";
// Style
import "../LoanList/LoanList.scss";

class LoanList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnalyst: props.loggedInUser.role === USER_ROLES.analyst,
      isOperationsOfficer:
        props.loggedInUser.role === USER_ROLES.operationsOfficer,
      isOperationsModerator:
        props.loggedInUser.role === USER_ROLES.operationsModerator,
      isAccountsOfficer:
        props.loggedInUser.role === USER_ROLES.accountsOfficer,
      isAnalystModerator:
        props.loggedInUser.role === USER_ROLES.analystModerator,
      LoanFilters: {
        minimartCode: props.searchFilter.code,
        matricesId: props.searchFilter.mid,
        applicationId: props.searchFilter.appid,
        minimartName: props.searchFilter.mn,
        userName: props.searchFilter.an ? props.searchFilter.an : "",
        provision: DEFAULT_PROVISION_GRADE,
        status: props.searchFilter.st
          ? props.searchFilter.st
          : STATUS_FOR_LOANS[0].value,
        category: DEFAULT_CATEGORY_TYPE,
        loanType: props.searchFilter.ty,
        fromDate: props.searchFilter.fd,
        toDate: props.searchFilter.td,
      },
      numberOfPages: props.numberOfPages,
      activePage: props.searchFilter.offset,

      recordsPerPage: props.searchFilter.limit,
      loanStatuses: STATUS_FOR_LOANS,
      provisions: PROVISION_GRADES,
      disabled: true,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  handleSearchCICodeChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        minimartCode: event.target.value,
      },
    });
  };

  handleSearchMatricesIdChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        matricesId: event.target.value,
      },
    });
  };

  handleSearchIdChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        applicationId: event.target.value,
      },
    });
  };

  handleSearchNameChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        minimartName: event.target.value,
      },
    });
  };

  handleSearchUserNameChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        userName:
          event.target.value === Strings("analystName") ||
            event.target.value === Strings("operationOfficerName")
            ? ""
            : event.target.value,
      },
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.LoanFilters.toDate)) {
          DateValue = this.state.LoanFilters.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.LoanFilters.fromDate)) {
          DateValue =
            moment(dateMoment).diff(
              this.state.LoanFilters.fromDate,
              "days"
            ) === 0
              ? this.state.LoanFilters.fromDate
              : this.state.LoanFilters.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({
      LoanFilters: { ...this.state.LoanFilters, [key]: DateValue },
    });
  };

  handleChangeStatusFilter = (event) => {
    if (event.target.value !== DEFAULT_LOAN_STATUS_SELECTION.value) {
      this.setState({
        LoanFilters: {
          ...this.state.LoanFilters,
          status: event.target.value,
          category:
            event.target.value !== DISBURSED
              ? DEFAULT_CATEGORY_TYPE
              : this.state.LoanFilters.category,
        },
        disabled: event.target.value === DISBURSED ? false : true,
      });
    } else {
      this.setState({
        LoanFilters: {
          ...this.state.LoanFilters,
          status: "",
          category: DEFAULT_CATEGORY_TYPE,
        },
        disabled: event.target.value === DISBURSED ? false : true,
      });
    }
  };

  handleProvisionFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        provision: event.target.value,
      },
    });
  };

  handleChangeTypeFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        loanType: event.target.value,
      },
    });
  };

  handleChangeCategoryFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        category: event.target.value,
      },
    });
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        LoanFilters: {
          minimartCode: "",
          matricesId: "",
          applicationId: "",
          minimartName: "",
          userName: "",
          status: DEFAULT_LOAN_STATUS.value,
          provision: DEFAULT_PROVISION_GRADE,
          loanType: DEFAULT_LOAN_TYPE,
          category: DEFAULT_CATEGORY_TYPE,
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
        },
      },
      () => {
        this.handleOnClickSearchFilter(true);
      }
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleOnClickSearchFilter({
      search: {
        minimartCode:
          this.state.LoanFilters.minimartCode !== ""
            ? this.state.LoanFilters.minimartCode
            : undefined,
        matricesId:
          this.state.LoanFilters.matricesId !== ""
            ? this.state.LoanFilters.matricesId
            : undefined,
        applicationId:
          this.state.LoanFilters.applicationId !== ""
            ? this.state.LoanFilters.applicationId
            : undefined,
        minimartName:
          this.state.LoanFilters.minimartName !== ""
            ? this.state.LoanFilters.minimartName
            : undefined,
        userName:
          this.state.LoanFilters.userName !== ""
            ? this.state.LoanFilters.userName
            : undefined,
        status:
          this.state.LoanFilters.status !== DEFAULT_LOAN_STATUS
            ? this.state.LoanFilters.status
            : undefined,
        loanType:
          this.state.LoanFilters.loanType !== DEFAULT_LOAN_TYPE
            ? this.state.LoanFilters.loanType
            : undefined,
        category:
          this.state.LoanFilters.category !== DEFAULT_CATEGORY_TYPE
            ? this.state.LoanFilters.category
            : undefined,
        provision:
          this.state.LoanFilters.provision !== DEFAULT_PROVISION_GRADE
            ? this.state.LoanFilters.provision
            : undefined,
        fromDate: this.state.LoanFilters.fromDate,
        toDate: this.state.LoanFilters.toDate,
      },
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  //render
  renderSearchFilter = () => {
    let LoanFilters;
    if (
      this.state.isAnalyst ||
      this.state.isOperationsOfficer ||
      this.state.isOperationsModerator ||
      this.state.isAccountsOfficer
    ) {
      LoanFilters = [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          handleOnChange: this.handleChangefromDate,
          fromDate: this.state.LoanFilters.fromDate,
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.LoanFilters.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
          column: 4,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchMatricesIdChange,
          Value: this.state.LoanFilters.matricesId,
          fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
          column: 2,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartCode"),
          handleOnChange: this.handleSearchCICodeChange,
          Value: this.state.LoanFilters.minimartCode,
          fieldname: SEARCH_FILTER_FIELDS.CODE,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("applicationId"),
          handleOnChange: this.handleSearchIdChange,
          Value: this.state.LoanFilters.applicationId,
          fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID,
          column: 2,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MiniMartname"),
          handleOnChange: this.handleSearchNameChange,
          Value: this.state.LoanFilters.minimartName,
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeTypeFilter,
          Value: this.state.LoanFilters.loanType,
          fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
          filterDropdownList: LOAN_TYPE,
          paddingTop: true,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeStatusFilter,
          Value: this.state.LoanFilters.status,
          fieldname: SEARCH_FILTER_FIELDS.STATUS,
          filterDropdownList: LOAN_HISTORY_STATUSES,
          paddingTop: true,
        },
      ];
      this.state.isOperationsModerator &&
        LoanFilters.push({
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleSearchUserNameChange,
          Value: this.state.LoanFilters.userName,
          fieldname: SEARCH_FILTER_FIELDS.OPERATION_OFFICER_NAME,
          filterDropdownList: this.props.userList,
          column: 2,
          paddingTop: 2,
        });
    } else if (this.state.isAnalystModerator) {
      LoanFilters = [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          handleOnChange: this.handleChangefromDate,
          fromDate: this.state.LoanFilters.fromDate,
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.LoanFilters.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
          column: 4,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchMatricesIdChange,
          Value: this.state.LoanFilters.matricesId,
          fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartCode"),
          handleOnChange: this.handleSearchCICodeChange,
          Value: this.state.LoanFilters.minimartCode,
          fieldname: SEARCH_FILTER_FIELDS.CODE,
          column: 2,
          inputType: "number",
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("applicationId"),
          handleOnChange: this.handleSearchIdChange,
          Value: this.state.LoanFilters.applicationId,
          fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID,
          column: 2,
          inputType: "number",
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MiniMartname"),
          handleOnChange: this.handleSearchNameChange,
          Value: this.state.LoanFilters.minimartName,
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleSearchUserNameChange,
          Value: this.state.LoanFilters.userName,
          fieldname: SEARCH_FILTER_FIELDS.ANALYST_NAME,
          filterDropdownList: this.props.userList,
          column: 2,
          paddingTop: true,
        },

        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeTypeFilter,
          Value: this.state.LoanFilters.loanType,
          fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
          filterDropdownList: LOAN_TYPE,
          column: 2,
          paddingTop: true,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeStatusFilter,
          Value: this.state.LoanFilters.status,
          fieldname: SEARCH_FILTER_FIELDS.STATUS,
          filterDropdownList: LOAN_HISTORY_STATUSES,
          paddingTop: true,
        },
      ];
    } else {
      LoanFilters = [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          handleOnChange: this.handleChangefromDate,
          fromDate: this.state.LoanFilters.fromDate,
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.LoanFilters.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
          column: 4,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchMatricesIdChange,
          Value: this.state.LoanFilters.matricesId,
          fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartCode"),
          handleOnChange: this.handleSearchCICodeChange,
          Value: this.state.LoanFilters.minimartCode,
          fieldname: SEARCH_FILTER_FIELDS.CODE,
          column: 2,
        },

        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("applicationId"),
          handleOnChange: this.handleSearchIdChange,
          Value: this.state.LoanFilters.applicationId,
          fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID,
          column: 2,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MiniMartname"),
          handleOnChange: this.handleSearchNameChange,
          Value: this.state.LoanFilters.minimartName,
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeTypeFilter,
          Value: this.state.LoanFilters.loanType,
          fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
          filterDropdownList: LOAN_TYPE,
          paddingTop: true,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeStatusFilter,
          Value: this.state.LoanFilters.status,
          fieldname: SEARCH_FILTER_FIELDS.STATUS,
          filterDropdownList: this.state.loanStatuses,
          paddingTop: true,
        },

        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          disabled: this.state.disabled,
          handleOnChange: this.handleChangeCategoryFilter,
          Value: this.state.LoanFilters.category,
          fieldname: SEARCH_FILTER_FIELDS.CATEGORY,
          column: 2,
          filterDropdownList: CATEGORY,
          paddingTop: true,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleProvisionFilter,
          Value: this.state.LoanFilters.provision,
          fieldname: SEARCH_FILTER_FIELDS.PROVISION,
          filterDropdownList: this.state.provisions,
          paddingTop: true,
        },
      ];
    }
    return (
      <SearchFilter
        filters={LoanFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingRight={0}
        paddingTop={2}
        removeLeftPadding={
          this.state.isAnalyst ||
          this.state.isAccountsOfficer ||
          this.state.isAnalystModerator
        }
      />
    );
  };

  render() {
    return (
      <div className='loanList container-fluid ListContainer'>
        {this.renderSearchFilter()}
        {
          <CustomerTypeLegend
            heading={Strings("ApplicationStatuses")}
            arrayOfTypes={LOAN_HISTORY_LEGENDS}
            paddingRight={4}
            key={Math.random()}
          />
        }
        {this.props.loans.length > 0 ? (
          <Row className='cardAlign'>
            <Col md={12} className='w-100 pr-0'>
              <Row className='w-100'>
                {this.props.loans.map((card, index) => {
                  return (
                    <CustomCard
                      card={card}
                      cardType='loan'
                      key={Math.random()}
                      handleCardClick={this.props.setLoanDetailsID}
                      isHistory={this.props.isHistory}
                      isAnalystModerator={
                        this.state.isAnalystModerator
                      }
                      isOperationsModerator={
                        this.state.isOperationsModerator
                      }
                    />
                  );
                })}
              </Row>
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </Row>
        ) : (
          <div className='noRecordsFound noBranchRecords'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default LoanList;
