//Components
import React from "react";
import { Strings } from "../../../resources/i18n/i18n";
import { Row, Col, Collapse, Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Rater from "react-rater";
import moment from "moment";
import _ from "lodash";

import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import EditableModal from "../../LoanDetails/EditableModal";
import idIcon from "../../../resources/images/ic_loan_update.svg";
//Constants
import {
  INVOICE_DATE_FORMAT,
  MINIMART_RATING,
  USER_ROLES,
  MODAL_TYPE,
  PAYMENT_STATUS,
  REQUEST_STATUS,
} from "../../../constants/appConstants";
import { TABLE_COLUMNS_CONST } from "../../LoanDetails/Constants.js";

//Utility
import Utility from "../../../utils/Utility";

//Styles
import "./CustomTableCard.scss";

//Assets
import editNote from "../../../resources/images/ic_edit.svg";

class CustomTableCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      rows: props.rows,
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.rows, this.props.rows)) {
      this.setState({
        rows: this.props.rows,
      });
    }
  }
  renderHeaderRow = (columns, isSummary, isExpandable) => {
    return (
      <Card className={"mt-3 headerRow py-2"}>
        <CardActions disableSpacing>
          {columns.map((column) => {
            return (
              <Col
                className={
                  column.name === "action"
                    ? "text-right"
                    : "text-center"
                }
                md={
                  !isSummary && //NOSONAR
                    isExpandable &&
                    (column.key === "ap" ||
                      column.key === "mn" ||
                      column.key === "receipt")
                    ? 2
                    : ""
                }
                key={Strings(column.name)}
              >
                <Typography className={"headerContent"}>
                  {Strings(column.name)}
                </Typography>
              </Col>
            );
          })}
        </CardActions>
      </Card>
    );
  };

  doNothing = () => {
    //This is intensional
  };

  renderExpandView = (row, isSummary) => {
    const ExpandMore = styled((props) => {
      const { expand, ...other } = props;
      return <IconButton {...other} />;
    })(({ theme, expand }) => ({
      transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    }));
    return (
      <Col className={"text-right"} key={Math.random()}>
        <ExpandMore
          expand={this.props.expanded.includes(
            row.id || row.pstid || row.mid
          )}
          onClick={() =>
            this.props.isWorkloadManagement
              ? this.props.handleExpandClick(row)
              : !isSummary //NOSONAR
                ? this.props.handleExpandClick(row.id || row.mid)
                : this.doNothing
          }
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Col>
    );
  };

  renderTypography = (row, column) => {
    return (
      <Typography
        className={
          column.key === "amountPaid" || //NOSONAR
            column.key === "amountToPay" ||
            column.key === "mmc"
            ? "text-center url cursorPointer textColor content text-break"
            : "content text-break "
        }
      >
        {this.renderTableData(row, column.key)}
      </Typography>
    );
  };

  rowExpandOnClick = (row, column, isSummary, callBack) => {
    !isSummary &&
      (column.key === "amountPaid" || column.key === "amountToPay")
      ? callBack(column.key, row)
      : column.key === "mmc" && //NOSONAR
        this.props.isWorkloadManagement
        ? callBack(row)
        : this.props.isWorkloadManagement || //NOSONAR
          this.props.MINIMART_REQUEST //NOSONAR
          ? this.doNothing()
          : callBack(row);
  };

  renderData = (row, column, isSummary, isExpandable, callBack) => {
    return (
      <Col
        onClick={() =>
          this.rowExpandOnClick(row, column, isSummary, callBack)
        }
        className={"text-center cursorPointer"}
        md={
          !isSummary && //NOSONAR
            isExpandable &&
            (column.key === "ap" ||
              column.key === "mn" ||
              column.key === "receipt")
            ? 2
            : ""
        }
        key={Math.random()}
        title={Strings(column.name)}
      >
        {this.renderTypography(row, column)}
      </Col>
    );
  };

  renderFirstRow = (
    columns,
    row,
    isSummary,
    isExpandable,
    callBack
  ) => {
    return (
      <CardActions>
        {columns.map((column) => {
          return !isSummary &&
            isExpandable &&
            column.key === "action" ? (
            this.renderExpandView(row, isSummary)
          ) : column.key === "selection" ? ( //NOSONAR
            <Col key={column.key}>
              <div className='d-flex justify-content-center w-100'>
                <CheckboxComponent
                  onChange={() => this.props.callBack(row.mid)}
                  checked={row.mid === this.props.isSelectedRow}
                />
              </div>
            </Col>
          ) : (
            this.renderData(
              row,
              column,
              isSummary,
              isExpandable,
              callBack
            )
          );
        })}
      </CardActions>
    );
  };

  renderWorkloadManagement = (row) => {
    return (
      <Row className='d-flex'>
        <Col
          md={3}
          className='justify-content-center align-self-center pr-0'
        >
          <span className={"label"}>
            {Strings(
              row.rl === "SALES_PERSON"
                ? "ExistingSalesPersonUser"
                : "ExistingMessengerUser"
            )}
          </span>
          {" : "}
          <span className={"content"}>
            {row.un ? row.un : Strings("NA")}
          </span>
        </Col>
        <Col
          md={2}
          className='justify-content-center align-self-center'
        >
          <span className={"label"}>
            {Strings(
              row.rl === "SALES_PERSON"
                ? "NewSalesPersonUser"
                : "NewMessengerUser"
            )}
          </span>
          {" : "}
        </Col>
        <Col md={3}>
          <TextFieldComponent
            type='select'
            handleChange={this.props.onUserChange}
            value={this.props.user}
          >
            {this.props.userList.map((user) => (
              <option key={user.value} value={user.value}>
                {user.label}
              </option>
            ))}
          </TextFieldComponent>
        </Col>
        <Col md={2}>
          <Button
            className='buttonBorder'
            onClick={() => {
              this.props.onSaveClick(row);
            }}
          >
            {Strings("Save")}
          </Button>
        </Col>
      </Row>
    );
  };

  renderWarning = (row) => {
    let warning = "";
    if (row.imb) {
      warning = <Row className="warningText">{Strings("OWNER_OF_MINIMART_IS_BLACKLISTED")}</Row>
    }
    return warning;
  }

  renderSecondRow = (row, rows) => {
    return (
      <Row>
        {rows.map((column) => (
          <Col
            md={this.props.AMORTIZATION ? 4 : 3}
            key={Math.random()}
          >
            <span className={"label"}>{Strings(column.name)}</span>
            {" : "}
            <span
              className={"content"}
              onClick={
                column.key === TABLE_COLUMNS_CONST.NOTE //NOSONAR
                  ? () =>
                    row[column.key] &&
                    this.props.showNotesModal(
                      row[TABLE_COLUMNS_CONST.INDEX],
                      row[column.key]
                        ? MODAL_TYPE.EDIT
                        : MODAL_TYPE.ADD_TYPE,
                      row[column.key]
                    )
                  : () => {
                    //This is intensional
                  }
              }
            >
              {this.renderTableData(row, column.key)}
            </span>
          </Col>
        ))}
      </Row>
    );
  };

  renderCollapseSection = (row, rows) => {
    return (
      <Collapse
        id={Math.random()}
        in={this.props.expanded.includes(
          row.id || row.pstid || row.mid
        )}
        timeout={200}
        unmountOnExit
      >
        <CardContent className='pt-0 arrowPointer tableRow'>
          <hr className={"my-2"} />
          {this.props.isWorkloadManagement
            ? this.renderWorkloadManagement(row)
            : this.renderSecondRow(row, rows)}
        </CardContent>
      </Collapse>
    );
  };

  renderNotes = (row, key) => {
    if (
      this.props.logInUserInfo.role ===
      USER_ROLES.collectionsOfficer &&
      !row[key] &&
      row[TABLE_COLUMNS_CONST.STATUS] !== PAYMENT_STATUS.DUE
    ) {
      return (
        <span
          className='pencil p-2'
          onClick={() =>
            this.props.showNotesModal(
              row["index"],
              MODAL_TYPE.ADD_TYPE
            )
          }
        >
          <img
            src={editNote}
            className={"editIcon ml-3 py-1"}
            alt={"edit"}
            title={Strings("Notes")}
          />
          {Strings("Note")}
        </span>
      );
    } else if (row[key]) {
      return (
        <span
          title={Strings("viewNote")}
          className={"url cursorPointer textColor"}
        >
          {Strings("viewNote")}
        </span>
      );
    } else {
      return Strings("NA");
    }
  };

  renderReceipts = (row, key) => {
    //NOSONAR
    return row[key].length > 0
      ? row[key].map((receipt) => {
        const colorText =
          typeof receipt === "string" && receipt.includes("NC")
            ? "conciliationTextColor"
            : typeof receipt === "string" && receipt.includes("NP") //NOSONAR
              ? "promotionTextColor"
              : typeof receipt === "string" && receipt.includes("PSW") //NOSONAR
                ? "webTextColor"
                : "textColor";
        return (
          <span
            key={receipt}
            title={receipt}
            className={`url cursorPointer ${colorText}`}
            onClick={() => {
              this.props.viewReceipt(receipt);
            }}
          >
            {receipt}
          </span>
        );
      })
      : Strings("NA");
  };

  renderTransferReceipts = (row, key) => {
    return row[key].length > 0
      ? row[key].map((receipt, rowIndex) => {
        const s3rUl = Utility.getDecryptedData(receipt.receiptUrl)
        return (
          <span
            key={receipt.transferReceiptId}
            title={receipt.transferReceiptId}
            className={`url cursorPointer textColor`}
            onClick={() => {
              window.open(s3rUl)
            }}
          >
            {`${row.index}-${rowIndex + 1}`}
          </span>
        )
      })
      : Strings("NA");
  }

  renderIndexFlag = (row, key) => {
    return row["rescheduledIndex"] ? (
      <Row className='w-100'>
        <Col md={3} className='pr-0'>
          <i className='fa fa-flag  '></i>
        </Col>
        <Col
          md={{ span: 7, offset: 2 }}
          className=' pr-0 text-left fa-flag-paddingLeft'
        >
          <span>{row[key]}</span>
        </Col>
      </Row>
    ) : (
      row[key]
    );
  };
  renderRestructuredLoanIcon=(row,key)=>{
    return(
    <>
     {(row.cli!==Strings("NA") || row.crli!==Strings("NA"))?(
      <Row className="justify-content-center">
        <div
          className='pr-0 text-left pl-0 my-auto'
        >
          <span>{row[key]}</span>
        </div>
        <div className='pr-0 pl-0' title={Strings("RestructuredLoan")}>
          <img src={idIcon} className="float-left" alt="Icon" />
        </div>
      </Row>
    ):(
      row[key]
    )}
    </>)
  }
  renderAmountPaid = (row, key) => {
    return (
      <>
        <span>
          {Utility.getCurrencyRepresentationOfAmount(row[key])}
        </span>

        {row["conciliationAmountPaid"] ? (
          <>
            &nbsp;&nbsp;
            <span className='conciliationTextColor'>
              {Utility.getCurrencyRepresentationOfAmount(
                row["conciliationAmountPaid"]
              )}
            </span>
          </>
        ) : null}
        {row["promotionAmountPaid"] ? (
          <>
            &nbsp;&nbsp;
            <span className='promotionTextColor'>
              {Utility.getCurrencyRepresentationOfAmount(
                row["promotionAmountPaid"]
              )}
            </span>
          </>
        ) : null}
      </>
    );
  };

  renderZones = (row, key) => {
    return this.props.isWorkloadManagement ? (
      <span>{row[key].join(", ")}</span>
    ) : row[key] ? ( //NOSONAR
      Strings(row[key])
    ) : (
      Strings("NA")
    );
  };

  renderRater = (row, key) => {
    return (
      <Rater
        title={row[key]}
        rating={row[key]}
        total={5}
        interactive={false}
      />
    );
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "ts":
      case "cat":
      case "ndt":
      case "dueDate":
      case "cd":
        return moment.utc(row[key]).format(INVOICE_DATE_FORMAT);

      case "rt":
        return parseFloat(row[key]).toFixed(2);

      case "pRat":
        return this.renderRater(row, key);

      case "mRat":
        return row[key] ? MINIMART_RATING[row[key]] : Strings("NA");

      case "ui":
      case "uc":
      case "ulf":
      case "atc":
      case "amountToPay":
      case "acic":
      case "caec":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "acid":
      case "acit":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "amountPaid":
        return this.renderAmountPaid(row, key);

      case "receipt":
        return this.renderReceipts(row, key);

      case "noteId":
        return this.renderNotes(row, key);
      case "index":
        return this.renderIndexFlag(row, key);
      case "mzn":
      case "zn":
        return row[key] !== null
          ? this.renderZones(row, key)
          : Strings("NA");
      case "nm":
        return row[key];
      case "transferReceipts":
        return this.renderTransferReceipts(row, key);
      case "paymentModes":
      case "paymentSources":
        return row[key].length > 0
          ? Strings(row[key].join(","))
          : Strings("NA");
      case "id":
        return this.renderRestructuredLoanIcon(row,key)
      default:
        return row[key] || row[key] === 0
          ? Strings(row[key])
          : Strings("NA");
    }
  };

  changedMinimartdetails = (row, newElement) => {
    return this.props.secondRowFirstSection.map((oldElement) => {
      return oldElement.name === newElement.name
        && row[oldElement.key]?.toString() !== row[newElement.key]?.toString()
    });
  };

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal,
      errorMessage: "",
    });
  };

  editMinimartDetails = (rowIndex) => {
    this.setState({
      index: rowIndex,
      openModal: !this.state.openModal,
      editMinimart: this.state.rows[rowIndex],
    });
  };

  onChange = (event) => {
    let value = event.target.value;
    this.setState({
      editMinimart: {
        ...this.state.editMinimart,
        [event.target.name]:
          event.target.name === "name" ? value.toUpperCase() : value,
      },
      errorMessage: "",
    });
  };

  onSubmitMinimartDetails = () => {
    if (
      this.state.editMinimart.name &&
      this.state.editMinimart.oid &&
      this.state.editMinimart.oid.toString().length === 11 &&
      this.state.editMinimart.phno &&
      this.state.editMinimart.phno.toString().length === 10 &&
      this.state.editMinimart.ct &&
      this.state.editMinimart.scr &&
      this.state.editMinimart.srt &&
      this.state.editMinimart.no &&
      this.state.editMinimart.ref
    ) {
      let rows = [...this.state.rows];
      rows[this.state.index] = this.state.editMinimart;
      this.setState({ rows, openModal: !this.state.openModal });
    } else if (
      this.state.editMinimart.phno.toString().length !== 10 ||
      (this.state.editMinimart.alph &&
        this.state.editMinimart.alph.toString().length !== 10)
    ) {
      this.setState({
        errorMessage: Strings("MobileNumberMustBeTenDigits"),
      });
    } else if (
      this.state.editMinimart.oid.toString().length !== 11 ||
      (this.state.editMinimart.aid &&
        this.state.editMinimart.aid.toString().length !== 11)
    ) {
      this.setState({
        errorMessage: Strings("CedulaIdMustBeElevenDigits"),
      });
    } else {
      this.setState({
        errorMessage: Strings("pleaseEnterRequiredFields"),
      });
    }
  };

  renderMinimartDetailsPopup = () => {
    let editableData = [
      {
        title: Strings("minimartName"),
        value: this.state.editMinimart.name,
        isMandatoryField: true,
        name: "name",
        type: "text",
        md: 12,
      },
      {
        title: Strings("Sector"),
        value: this.state.editMinimart.scr,
        isMandatoryField: true,
        name: "scr",
        type: "text",
      },
      {
        title: Strings("Street"),
        value: this.state.editMinimart.srt,
        isMandatoryField: true,
        name: "srt",
        type: "text",
      },
      {
        title: Strings("number"),
        value: this.state.editMinimart.no,
        isMandatoryField: true,
        name: "no",
        type: "text",
      },
      {
        title: Strings("Reference"),
        value: this.state.editMinimart.ref,
        isMandatoryField: true,
        name: "ref",
        type: "text",
      },
      {
        title: Strings("City"),
        value: this.state.editMinimart.ct,
        isMandatoryField: true,
        name: "ct",
        type: "text",
      },
      {
        title: Strings("ownerCedulaNumber"),
        value: this.state.editMinimart.oid,
        isMandatoryField: true,
        name: "oid",
        type: "number",
      },
      {
        title: Strings("adminId"),
        value: this.state.editMinimart.aid,
        isMandatoryField: false,
        name: "aid",
        type: "number",
      },
      {
        title: Strings("PhoneNumber"),
        value: this.state.editMinimart.phno,
        isMandatoryField: true,
        name: "phno",
        type: "number",
      },
      {
        title: Strings("AlternateNumber"),
        value: this.state.editMinimart.alph,
        isMandatoryField: false,
        name: "alph",
        type: "number",
      },
    ];
    return (
      <EditableModal
        open={this.state.openModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("EditMinimartDetails")}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.toggleModal,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.onSubmitMinimartDetails,
        }}
        onChange={this.onChange}
        SubmitMinimartDetails={this.onSubmitMinimartDetails}
        editableData={editableData}
        errorMessage={this.state.errorMessage}
      />
    );
  };

  handleUpdateRequest = (st, rowIndex) => {
    let rows = [...this.state.rows];
    rows[rowIndex].st = st;
    this.setState({ rows }, () => {
      this.props.handleUpdateRequest(
        this.state.rows,
        this.state.rows[rowIndex]
      );
    });
  };

  renderMinimartRequestCollapseSection = (row, rowIndex) => {
    return (
      <Collapse
        id={Math.random()}
        in={this.props.expanded.includes(row.id)}
        timeout={200}
        unmountOnExit
      >
        <CardContent className='pt-0 arrowPointer tableRow'>
          <hr className={"my-2"} />
          {this.props.warning && this.renderWarning(row)}
          <Row>
            <Col md={6} className={"border-right"}>
              <div className='text-center headerContent'>
                {Strings("existingMinimartDetails")}
              </div>
              {this.props.secondRowFirstSection.map((element) => (
                <div key={Math.random()}>
                  <span className={"label"}>{`${Strings(
                    element.name
                  )} : `}</span>
                  <span className={"content"}>
                    {row[element.key]
                      ? row[element.key]
                      : Strings("NA")}
                  </span>
                </div>
              ))}
            </Col>
            <Col md={6}>
              <div className='text-center headerContent'>
                {Strings("newMinimartDetails")}
                {!this.props.readOnly && <i
                  className='fa fa-edit px-1 cursorPointer textColor'
                  onClick={() => this.editMinimartDetails(rowIndex)}
                  title={Strings("EditMinimartDetails")}
                />}
              </div>
              {this.props.secondRowSecondSection.map((element) => {
                return (
                  <div key={Math.random()}>
                    <span className={"label"}>{`${Strings(
                      element.name
                    )} : `}</span>

                    <span
                      className={
                        this.changedMinimartdetails(
                          row,
                          element
                        ).includes(true)
                          ? "content textColor"
                          : "content"
                      }
                    >
                      {row[element.key]
                        ? row[element.key]
                        : Strings("NA")}
                    </span>
                    {element.key === "oid" &&
                      row["ofp"] &&
                      row["obp"] && (
                        <>
                          <span
                            className='content textColor cursorPointer pl-2'
                            onClick={() =>
                              this.props.downloadPhoto(row["ofp"])
                            }
                          >
                            {Strings("downloadFrontPhoto")}
                          </span>
                          <span
                            className='content textColor cursorPointer pl-2'
                            onClick={() =>
                              this.props.downloadPhoto(row["obp"])
                            }
                          >
                            {Strings("downloadBackPhoto")}
                          </span>
                        </>
                      )}
                    {element.key === "aid" &&
                      row["nfp"] &&
                      row["nbp"] && (
                        <>
                          <span
                            className='content textColor cursorPointer pl-2'
                            onClick={() =>
                              this.props.downloadPhoto(row["nfp"])
                            }
                          >
                            {Strings("downloadFrontPhoto")}
                          </span>
                          <span
                            className='content textColor cursorPointer pl-2'
                            onClick={() =>
                              this.props.downloadPhoto(row["nbp"])
                            }
                          >
                            {Strings("downloadBackPhoto")}
                          </span>
                        </>
                      )}
                  </div>
                );
              })}
              <Row className='pull-right'>
                <Button
                  className='capitalText buttonBorder mx-2'
                  onClick={() =>
                    this.props.handleViewCedulaHistory(row["mid"])
                  }
                >
                  {Strings("cedulaHistory")}
                </Button>
                {!this.props.readOnly &&
                  <><Button
                    className='capitalText buttonBorder mx-2'
                    onClick={() =>
                      this.handleUpdateRequest(
                        REQUEST_STATUS.rejected,
                        rowIndex
                      )
                    }
                  >
                    {Strings("reject")}
                  </Button>
                    <Button
                      className='capitalText buttonBackground mx-2'
                      onClick={() =>
                        this.handleUpdateRequest(
                          REQUEST_STATUS.approved,
                          rowIndex
                        )
                      }
                      disabled={
                        !(
                          row["scr"] &&
                          row["srt"] &&
                          row["no"] &&
                          row["ref"] &&
                          row["ct"]
                        )
                      }
                    >
                      {Strings("approve")}
                    </Button>
                  </>}
              </Row>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
    );
  };

  render() {
    return (
      <div className='customTableCard'>
        {this.state.openModal && this.renderMinimartDetailsPopup()}
        {this.renderHeaderRow(
          this.props.columns,
          this.props.isSummary,
          this.props.isExpandable
        )}
        <div
          className={this.props.isSignupRequest ? "scrollable" : ""}
        >
          {this.state.rows.map((row, rowIndex) => {
            let workLoadClassName;
            if (row.rl === "SALES_PERSON") {
              workLoadClassName = "salesPersonRow";
            } else {
              workLoadClassName = "allRows";
            }
            return (
              <Card
                id={Math.random()}
                className={
                  this.props.isExpandable &&
                    this.props.expanded.includes(row.id || row.pstid) &&
                    !this.props.isSummary &&
                    this.props.isExpandable
                    ? `highlighted tableRow py-1 ${workLoadClassName}`
                    : `tableRow py-1 ${workLoadClassName}`
                }
                key={Math.random()}
              >
                {this.renderFirstRow(
                  this.props.columns,
                  row,
                  this.props.isSummary,
                  this.props.isExpandable,
                  this.props.callBack
                )}
                {this.props.MINIMART_REQUEST
                  ? this.renderMinimartRequestCollapseSection(
                    row,
                    rowIndex
                  )
                  : !this.props.isSummary &&
                  this.props.isExpandable &&
                  this.renderCollapseSection(
                    row,
                    this.props.secondRowColumns,
                  )}
              </Card>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CustomTableCard;
