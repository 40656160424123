import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button } from "react-bootstrap";

//Components
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
import {
  INVOICE_DATE_FORMAT,
  REQUEST_STATUS,
  USER_ROLES,
} from "../../constants/appConstants";
import { NEW_REPAYMENT_PLAN_TABLE_COLUMNS } from "./Constants";

class LoanDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessage: props.errorMessage,
      requestData: props.requestData,
      readOnly: props.readOnly,
      isAssignRepayment: props.isAssignRepayment,
      dataForTable: [],
      requestNote: props.requestNote,
      isAuditor: this.props.loggedInUser.role === USER_ROLES.auditor,
      editedRepaymentData: props.editedRepaymentData,
      total: 0
    };
  }

  componentDidMount() {
    this.props.getRepaymentData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
    if (prevProps.readOnly !== this.props.readOnly) {
      this.setState({
        readOnly: this.props.readOnly,
      });
    }
    if (prevProps.editedRepaymentData !== this.props.editedRepaymentData) {
      this.setState({
        editedRepaymentData: this.props.editedRepaymentData,
      });
    }
    if (prevProps.isAssignRepayment !== this.props.isAssignRepayment) {
      this.setState({
        isAssignRepayment: this.props.isAssignRepayment,
      });
    }
    if (prevProps.requestNote !== this.props.requestNote) {
      this.setState({
        requestNote: this.props.requestNote,
      });
    }
  }

  //to close modal
  closeModal = () => {
    this.props.modalButtonCancel.onClick();
  };

  onChangeInput = (event, row) => {
    let editedRepaymentData = { ...this.state.editedRepaymentData };
    let value = event.target.value;
    editedRepaymentData[row.default] = value;
    this.setState({
      editedRepaymentData,
      total: parseInt(editedRepaymentData.term) * parseInt(editedRepaymentData.EMIAmount)
    }, () => {
      if (row.default === "frequency") {
        this.props.getPossibleCollectionCycles({
          fr: editedRepaymentData.frequency,
        }, () => { })
      }
    });
  }

  renderTextField = (key, row) => {
    return (
      <TextFieldComponent
        type="number"
        handleChange={(event) => this.onChangeInput(event, row)}
        name={key}
        value={this.state.editedRepaymentData[row.default]}
        placeholder={Strings(key)}
        disabled={row.disabled}
      />
    )
  }

  renderSelectField = (key, row) => {
    return (
      <TextFieldComponent
        type='select'
        handleChange={(event) => this.onChangeInput(event, row)}
        placeholder={Strings(key)}
        value={this.state.editedRepaymentData[row.default]}
        name={key}
        disabled={row.dropdownList.length === 0 || row.disabled}
      >
        <option default value=''>
          {Strings(row.default)}
        </option>
        {row.dropdownList.map((option) => {
          return (
            <option
              key={option.value || option}
              value={option.value || option}
            >
              {Strings(option.label) || Strings(option)}
            </option>
          );
        })}
      </TextFieldComponent>
    )
  }


  renderDataForTable = (row, column) => {
    return row["type"] === "number"
      ? this.renderTextField(column, row)
      : this.renderSelectField(column, row);

  }

  renderEditableDataTable = () => {
    let renderTotal = this.props.totalAmount ? this.props.totalAmount : this.state.total;
    return (
      <>
        <Row className="w-100 py-2 my-2">
          {
            NEW_REPAYMENT_PLAN_TABLE_COLUMNS.map((column) => {
              return (
                <Col md={4} key={column} className="header">
                  {Strings(column)}
                </Col>
              );
            })
          }
        </Row>

        {this.props.repaymentData.map((row) => {
          return <Row className="w-100 my-2 d-flex align-items-center border-bottom pb-2"> {NEW_REPAYMENT_PLAN_TABLE_COLUMNS.map((column) => {
            return column === "requested"
              ? <Col md={4}>{this.renderDataForTable(row, column)}</Col>
              : <Col md={4}>{row[column] ? Strings(row[column]) : Strings("NA")}</Col>
          })}</Row>
        })}
        <Row className="w-100 my-2">
          <Col md={4}>{Strings("Total")}</Col>
          <Col md={4}></Col>
          <Col md={4} className="text-center">{isNaN(renderTotal) ? "" : renderTotal}</Col>
        </Row>
      </>
    );
  };

  renderTextNotesRow = (
    role,
    id,
    placeholder,
    handleChange,
    value,
    disabled
  ) => {
    return (
      <Row className='subHeaderApplication addOrUpdateUserFontSize'>
        <Col md={{ span: 12 }} className='px-0 py-2'>
          {Strings(role) + " " + Strings("Notes")}
          <Row className='mt-2'>
            <Col md={{ span: 12 }} className='noPadding'>
              <textarea
                id={id}
                type='text'
                className='noMargin w-100'
                placeholder={placeholder}
                onChange={handleChange}
                maxLength={500}
                value={value}
                disabled={disabled}
                cols={51}
                rows={5}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  renderInvoiceDateRow = (roleLabel, role, dateLabel, date) => {
    return (
      <Row>
        <Col md={2} className='titleText noPadding'>
          {Strings(roleLabel)}
        </Col>
        : &nbsp;
        <Col md={3} className='detailText noPadding '>
          {role}
        </Col>
        <Col md={4} className='titleText'>
          {Strings(dateLabel)}
        </Col>
        : &nbsp;
        <Col md={2} className='detailText noPadding'>
          {moment(date).format(INVOICE_DATE_FORMAT)}
        </Col>
      </Row>
    );
  };

  renderButtons = () => {
    return (
      <div className='loanDetailModalButton'>
        {this.props.modalButtonCancel && (
          <Button
            className={
              "primaryModalButton buttonMinWidth marginCancelButton buttonBorder " +
              this.props.modalButtonCancelStyle
            }
            onClick={this.closeModal}
          >
            {this.props.modalButtonCancel.text}
          </Button>
        )}
        {this.props.modalButtonOk && !this.state.readOnly && (
          <Button
            className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
            onClick={() => this.props.requestForTakeOffLateFees(this.state.editedRepaymentData)}
            disabled={
              (!this.state.isAssignRepayment && !this.props.tla) ||
              this.state.isAuditor
            }
          >
            {this.props.modalButtonOk.text}
          </Button>
        )}
        {this.state.requestData &&
          !this.props.isCollectionOfficer &&
          this.renderApproveRejectButton(
            this.props.onRejectRequest,
            this.props.modalButtonReject.text,
            true
          )}
        {this.state.requestData &&
          !this.props.isCollectionOfficer &&
          this.renderApproveRejectButton(
            this.props.onApproveRequest,
            this.props.modalButtonApprove.text,
            false
          )}
      </div>
    );
  };

  renderApproveRejectButton = (
    handleClick,
    modalButtonText,
    isRejected
  ) => {
    let disabledOnTheBasisOfUserRoles =
      this.props.loggedInUserInfo.role === USER_ROLES.administrativeManager
      || this.props.loggedInUserInfo.role === USER_ROLES.technology;
    if (!this.props.isAssignRepayment) {
      disabledOnTheBasisOfUserRoles =
        disabledOnTheBasisOfUserRoles
        || this.props.loggedInUserInfo.role === USER_ROLES.administrativeManager
        || this.props.loggedInUserInfo.role === USER_ROLES.technology;
    }
    let disabledOnTheBasisOfStatus =
      this.state.requestData.status === REQUEST_STATUS.approved ||
      this.state.requestData.status === REQUEST_STATUS.rejected;

    return (
      <Button
        className={`primaryModalButton marginCancelButton ${isRejected ? " rejectButtonBackground" : " buttonBackground"
          } buttonMinWidth`}
        onClick={handleClick}
        disabled={
          disabledOnTheBasisOfStatus ||
          disabledOnTheBasisOfUserRoles
        }
      >
        {modalButtonText}
      </Button>
    );
  };

  render() {
    return (
      <div>
        {/* general modal */}
        <Modal
          // Change visibility of modal based on props.
          open={this.props.open}
          onClose={this.closeModal}
          //Modal opens with specified messages in props
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          showCloseIcon={this.props.modalShowCloseIcon}
          classNames={{
            modal: "loanDetailModal",
          }}
          center
        >
          <Row
            className={"loanDetailModal"}
          >
            <Col md={12} className='loanDetailModalHeader pl-3'>
              {this.props.headerText}
            </Col>
            {/* Show only specified props of modal */}
            <Col md={12} className='modalContent pt-3'>
              {this.props.errorMessage && (
                <Row className='errors px-0 pt-0 pb-2'>
                  {Strings(this.props.errorMessage)}
                </Row>
              )}
              {this.state.readOnly &&
                this.state.requestData.status && (
                  <Row
                    className={
                      this.state.requestData.status ===
                        REQUEST_STATUS.rejected
                        ? "errors bold noPadding"
                        : "success bold noPadding"
                    }
                  >
                    {Strings(this.state.requestData.status)}
                  </Row>
                )}
              {this.state.readOnly &&
                this.renderInvoiceDateRow(
                  "requester",
                  this.state.requestData.requester,
                  "RequestDate",
                  this.state.requestData.applicationDate
                )}{" "}
              {this.state.readOnly &&
                this.state.requestData.approver &&
                this.renderInvoiceDateRow(
                  "approver",
                  this.state.requestData.approver,
                  "approvalDate",
                  this.state.requestData.approvalDate
                )}
              {this.state.readOnly && <hr className='newHr' />}
              {!this.state.isAssignRepayment && (
                <Row className='subHeaderApplication addOrUpdateUserFontSize'>
                  <Col md={{ span: 12 }} className='pl-0 pr-3'>
                    {Strings("amt")}
                    <span className='mandatoryFields'>*</span>
                    <Row className='mt-1'>
                      <Col md={{ span: 8 }} className='noPadding'>
                        <TextFieldComponent
                          id='amount'
                          type='number'
                          className='noMargin'
                          placeholder={Strings("Amount")}
                          handleChange={this.props.onAmountChange}
                          maxLength={20}
                          value={
                            this.state.requestData?.request.amount
                          }
                          disabled={this.state.readOnly}
                        />
                      </Col>
                    </Row>{" "}
                  </Col>
                </Row>
              )}
              {this.state.isAssignRepayment && (
                <Row className='subHeaderApplication addOrUpdateUserFontSize noPadding'>
                  {this.renderEditableDataTable()}
                </Row>
              )}
              {this.renderTextNotesRow(
                "requester",
                "Notes",
                Strings("Notes"),
                this.props.onNotesChange,
                this.state.requestData?.request.note,
                this.state.readOnly
              )}
              {this.state.readOnly &&
                this.renderTextNotesRow(
                  "approver",
                  "approver",
                  Strings("approver") + " " + Strings("Notes"),
                  this.props.onRequestNotesChange,
                  this.state.requestNote
                    ? this.state.requestNote
                    : "",
                  this.state.requestData.approval
                )}
              {this.renderButtons()}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
});

export default connect(mapStateToProps)(LoanDetailModal);
