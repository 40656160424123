import React from "react";
import { Row, Button } from "react-bootstrap";

//Component

//Action

//Constants
import {
  ANALYST_ACTION,
  LOAN_TYPES,
  OPERATIONS_OFFICER_ACTION,
} from "./Constants";
import { LOAN_INITIATED_FROM } from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility

//Styles
import "./AnalystApproval.scss";

//Assets

class AnalystActionButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderAnalyst = () => {
    return (
      <React.Fragment>
        <div>
          {(this.props.initiatedFrom === LOAN_INITIATED_FROM.CORE && !this.props.minimart?.iob) && (
            <Button
              className='capitalText buttonBackground pull-right mx-2'
              onClick={() =>
                this.props.openNotesModal(ANALYST_ACTION.INCOMPLETE)
              }
              disabled={
                this.props.isDownpaymentCollected ||
                this.props?.isWrongLoanCombination ||
                this.props.isMultipleActiveLoans
              }
            >
              {Strings("incomplete")}
            </Button>
          )}
        </div>
        {!this.props.minimart?.iob && <div>
          <Button
            className='capitalText buttonBackground pull-right mx-2'
            onClick={() =>
              this.props.openNotesModal(ANALYST_ACTION.APPROVE)
            }
            disabled={
              this.props.docDeleted ||
              this.props?.isWrongLoanCombination ||
              this.props.isMultipleActiveLoans ||
              this.props.isAddressSplit
            }
          >
            {Strings("approve")}
          </Button>
        </div>}
        {!this.props.minimart?.iob &&
          this.props.loanType === LOAN_TYPES.CASH_TOPUP &&
          this.props.loanId && (
            <div>
              <Button
                className='capitalText buttonBackground pull-right mx-2'
                onClick={() =>
                  this.props.openNotesModal(ANALYST_ACTION.APPROVE_WITH_CONCILIATION)
                }
                disabled={
                  this.props.docDeleted ||
                  this.props?.isWrongLoanCombination ||
                  this.props.isMultipleActiveLoans ||
                  this.props.isAddressSplit ||
                  this.props.isLoanFormFilled === false
                }
              >
                {Strings("conciliationApprove")}
              </Button>
            </div>
          )}
        <div>
          <Button
            className='capitalText buttonBorder  pull-right mx-2'
            onClick={() =>
              this.props.openNotesModal(ANALYST_ACTION.REJECT)
            }
          >
            {Strings("reject")}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  onRemittanceDeliveryClicked = () => {
    if (
      this.props.minimart.inm === true &&
      (this.props.minimart.vd === null ||
        this.props.minimart.vd === "")
    ) {
      this.props.setVisitDayRequiredError(
        Strings("pleaseEnterRequiredFields")
      );
    } else {
      this.props.submitInput();
    }
  };
  onDisbursedClicked = () => {
    if (
      this.props.minimart.inm === true &&
      (this.props.minimart.vd === null ||
        this.props.minimart.vd === "")
    ) {
      this.props.setVisitDayRequiredError(
        Strings("pleaseEnterRequiredFields")
      );
    } else if (
      (this.props.loanType === LOAN_TYPES.CONSUMER ||
        this.props.loanType === LOAN_TYPES.MOTORCYCLE_LOAN) &&
      this.props.orderId === ""
    ) {
      this.props.setOperationsInputError(
        Strings("pleaseEnterRequiredFields")
      );
    } else if (
      (this.props.loanType === LOAN_TYPES.CONSUMER ||
        this.props.loanType === LOAN_TYPES.MOTORCYCLE_LOAN) &&
      this.props.oldOrderId === ""
    ) {
      this.props.setOperationsInputError(Strings("orderIdError"));
    }
    else if (
      this.props.loanType === LOAN_TYPES.EXTERNAL_FACTORING &&
      this.props.operationInputErrorCheck &&
      this.props.transferReceipt === null
    ) {
      this.props.setOperationsInputError(Strings("pleaseEnterRequiredFields"));
    } else {
      this.props.openNotesModal(OPERATIONS_OFFICER_ACTION.DISBURSE);
    }
  };

  renderOperationsOfficer = () => {
    let isConciliatedLoanClosed = this.props.isConciliatedLoanClosed?.some(item => item.iclc);
    return (
      <React.Fragment>
        {!this.props.minimart?.iob && !this.props.isHistory && <div>
          <Button
            className='capitalText buttonBackground mx-2'
            onClick={this.onRemittanceDeliveryClicked}
            disabled={
              !this.props.isRemittanceRequired ||
              this.props.isWrongLoanCombination ||
              this.props.isMultipleActiveLoans ||
              this.props.isICL ||
              this.props.isAddressSplit ||
              isConciliatedLoanClosed
            }
          >
            {Strings("remittanceDelivery")}
          </Button>
        </div>}
        {!this.props.minimart?.iob && <div>
          <Button
            className='capitalText buttonBackground mx-2'
            onClick={this.onDisbursedClicked}
            disabled={
              this.props.isWrongLoanCombination ||
              this.props.isMultipleActiveLoans ||
              this.props.isICL ||
              this.props.isAddressSplit ||
              isConciliatedLoanClosed
            }
          >
            {Strings("Disburse")}
          </Button>
        </div>}
        {(this.props.loanType === LOAN_TYPES.FAST_CASH
          || this.props.loanType === LOAN_TYPES.FACTORING
          || this.props.minimart?.iob
          || isConciliatedLoanClosed) && (
            <div>
              <Button
                className='capitalText buttonBorder mx-2'
                onClick={() =>
                  this.props.openNotesModal(ANALYST_ACTION.REJECT)
                }
              >
                {Strings("reject")}
              </Button>
            </div>)}
      </React.Fragment >
    );
  };

  render() {
    return (
      <div className='analystButton'>
        <Row className="d-flex justify-content-between">
          <div className='pl-0'>
            <Button
              className='capitalText buttonBorder'
              onClick={this.props.goBack}
            >
              {Strings("cancel").toUpperCase()}
            </Button>
          </div>
          <div className="d-flex">
            {(this.props.isAnalyst
              || this.props.isAnalystModerator) &&
              !this.props.isHistory &&
              this.renderAnalyst()}
            {this.props.featureName ===
              "OPERATIONS_OFFICER_DASHBOARD" &&
              (this.props.isOperationsOfficer ||
                this.props.isOperationsModerator || this.props.isOperationsManager) &&
              !this.props.isHistory &&
              this.renderOperationsOfficer()}
          </div>
        </Row>
      </div>
    );
  }
}

export default AnalystActionButtons;
