import React from "react";
import { FormControl } from "react-bootstrap";

//Constants
import { SEARCH_FILTER_TYPE } from "../../../constants/appConstants";

//Styles
import "./TextFieldComponent.scss";

//Utils
import Utility from "../../../utils/Utility";

class TextFieldComponent extends React.Component {
  /**
   * Function to set maximum length of an input string
   */
  setMaxLength = (event) => {
    if (
      this.props.maxLength &&
      event.target.value.toString().length >= this.props.maxLength
    ) {
      event.target.value = event.target.value.substr(
        0,
        this.props.maxLength
      );
      event.preventDefault();
    }
  };

  defineProps = () => {
    //NOSONAR
    let classes = this.props.disabled ? "disabled" : "";
    let onKeyDown;
    let onPaste;
    if (this.props.textAlignCenter) {
      classes += " centerAlign";
    }
    if (this.props.readOnly || this.props.disabled) {
      classes += " readOnly";
    }
    if (this.props.type === SEARCH_FILTER_TYPE.NUMBER) {
      classes += " removeStepper";
    }
    if (!this.props.halfWidth) classes += " fullWidth";
    if (this.props.className) classes += " " + this.props.className;
    if (this.props.type === SEARCH_FILTER_TYPE.NUMBER) {
      onKeyDown = Utility.allowOnlyNumericValue;
      onPaste = Utility.allowOnlyNumericValue;
    } else if (this.props.onKeyDown) {
      onKeyDown = this.props.onKeyDown
    } else {
      onKeyDown = () => { };
      onPaste = () => { };
    }
    let componentProps = {
      id: this.props.id ? this.props.id : "",
      readOnly: this.props.readOnly,
      value: this.props.value,
      title: this.props.title ? this.props.title : "",
      placeholder: this.props.placeholder,
      onChange: this.props.handleChange,
      onClick: this.props.onClick
        ? this.props.onClick
        : () => {
          //This is intensional
        },
      onInput: (event) => this.setMaxLength(event),
      onKeyUp: this.props.onKeyUp
        ? this.props.onKeyUp
        : () => {
          //This is intensional
        },
      onKeyDown: onKeyDown,
      onPaste: onPaste,
      onKeyPress: this.props.onKeyPress
        ? this.props.onKeyPress
        : () => {
          //This is intensional
        },
      onFocus: this.props.onFocus
        ? this.props.onFocus
        : () => {
          //This is intensional
        },
      disabled: this.props.disabled,
      className: classes + (this.props.errorClass ? this.props.errorClass : " "),
      defaultValue: this.props.defaultValue,
      name: this.props.name ? this.props.name : "",
    };
    if (this.props.children) {
      componentProps["children"] = this.props.children;
    }
    if (this.props.type) {
      componentProps["type"] = this.props.type;
      this.props.type === SEARCH_FILTER_TYPE.SELECT &&
        (componentProps["as"] = this.props.type);
    } else {
      componentProps["type"] = "text";
    }

    return componentProps;
  };

  render() {
    let placeholderTopStyle;
    if (!this.props.required) {
      placeholderTopStyle = this.props.placeholderTopClass
        ? "placeholderTop inputPlaceholderTop" + this.props.placeholderTopClass
        : "placeholderTop inputPlaceholderTop";
    } else {
      placeholderTopStyle = "position-relative ";
    }

    let componentProps = this.defineProps();
    return (
      <div
        className={
          this.props.halfWidth
            ? "textFieldComponent halfWidth"
            : "textFieldComponent fullWidth"
        }
      >
        {this.props.placeholderTop && (
          <div>
            <span className={placeholderTopStyle}>
              {this.props.placeholderTop}
            </span>
            {this.props.required && (
              <span className='requiredFields'>*</span>
            )}
          </div>
        )}
        {this.props.addOn && (
          <div className='alignImage'>
            <img src={this.props.addOnImage} alt='' />
          </div>
        )}
        <FormControl {...componentProps} />
        {this.props.showIcon && (
          <i
            className={
              this.props.showIcon && this.props.iconClassName
                ? "textFieldIcon " + this.props.iconClassName
                : ""
            }
            onClick={
              this.props.onIconClick
                ? this.props.onIconClick
                : () => {
                  //This is intensional
                }
            }
          />
        )}
        {this.props.showPasswordIcon
          && this.props.value !== ""
          && (this.props.type === "password" ? <i
            className={"textFieldIcon fa fa-eye"}
            onClick={this.props.showOrHidePassword}
          /> : <i
            className={"textFieldIcon fa fa-eye-slash"}
            onClick={this.props.showOrHidePassword} />
          )}
      </div>
    );
  }
}

export default TextFieldComponent;
