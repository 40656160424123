import React from "react";
import moment from "moment";
import { Row, Col, Button } from "react-bootstrap";
import { Strings } from "../../resources/i18n/i18n";
import {
  STATUS_FOR_INVOICES,
  DEFAULT_INVOICE_STATUS,
  NEW_DATE_FORMAT,
  INVOICE_DATE_FORMAT,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  INVOICE_TYPES,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";

import "./FileContent.scss";

class FileContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileId: props.fileContent.id,
      ciId: "",
      matricesId: "",
      invoiceNumber: "",
      status: "",
      fromDate: undefined,
      toDate: undefined,
      invoiceType: "",
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      disabled: true,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  handleSearchCiIdChange = (event) => {
    this.setState({ ciId: event.target.value });
  };

  handleSearchMatricesIdChange = (event) => {
    this.setState({ matricesId: event.target.value });
  };

  handleInvoiceNumberChange = (event) => {
    this.setState({
      invoiceNumber: event.target.value,
    });
  };

  handleChangeStatusFilter = (event) => {
    this.setState({ status: [event.target.value] });
  };

  handleChangeInvoiceType = (event) => {
    this.setState({
      invoiceType:
        event.target.value === Strings("InvoiceType")
          ? ""
          : event.target.value,
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(NEW_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (
          dateMoment.isBefore(this.state.fromDate) &&
          this.state.fromDate !== undefined
        ) {
          DateValue = this.state.toDate;
        } else {
          DateValue = dateMoment.format(NEW_DATE_FORMAT);
        }
        break;
      default:
    }

    this.setState({
      [key]: DateValue,
    });
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleOnClickSearchFilter({
      fileId:
        this.state.fileId !== "" ? this.state.fileId : undefined,
      minimartCode:
        this.state.ciId !== "" ? this.state.ciId : undefined,
      matricesId:
        this.state.matricesId !== ""
          ? this.state.matricesId
          : undefined,
      invoiceNumber:
        this.state.invoiceNumber !== ""
          ? this.state.invoiceNumber
          : undefined,
      status:
        !this.state.status.includes(DEFAULT_INVOICE_STATUS) &&
          this.state.status.length > 0
          ? this.state.status
          : undefined,
      fromDate:
        this.state.fromDate !== undefined
          ? moment(this.state.fromDate).format(NEW_DATE_FORMAT)
          : undefined,
      toDate:
        this.state.toDate !== undefined
          ? moment(this.state.toDate).format(NEW_DATE_FORMAT)
          : undefined,
      it:
        this.state.invoiceType !== ""
          ? this.state.invoiceType
          : undefined,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
        : this.state.activePage - 1,
    });
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        fileId: this.props.fileContent.id,
        ciId: "",
        matricesId: "",
        invoiceNumber: "",
        invoiceType: "",
        status: [DEFAULT_INVOICE_STATUS.value],
        fromDate: undefined,
        toDate: undefined,
      },
      () => this.handleOnClickSearchFilter(true)
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  //render
  renderSearchFilter = () => {
    let InvoiceFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("MatricesID"),
        handleOnChange: this.handleSearchMatricesIdChange,
        Value: this.state.matricesId,
        fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("CIID"),
        handleOnChange: this.handleSearchCiIdChange,
        Value: this.state.ciId,
        fieldname: SEARCH_FILTER_FIELDS.CI_ID,
      },

      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("InvoiceNumber"),
        handleOnChange: this.handleInvoiceNumberChange,
        Value: this.state.invoiceNumber,
        fieldname: SEARCH_FILTER_FIELDS.INVOICE_NUMBER,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeStatusFilter,
        Value: this.state.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: STATUS_FOR_INVOICES,
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        fromDate: this.state.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        dateFormat: INVOICE_DATE_FORMAT,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeInvoiceType,
        Value: this.state.invoiceType,
        fieldname: SEARCH_FILTER_FIELDS.INVOICE_TYPE,
        filterDropdownList: INVOICE_TYPES,
        paddingTop: 3,
      },
    ];
    return (
      <Col md={11} className='noPadding'>
        <SearchFilter
          filters={InvoiceFilters}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleOnClickResetFilter={this.handleOnClickResetFilter}
          paddingRight={0}
          paddingTop={2}
          removeLeftPadding={true}
        />{" "}
      </Col>
    );
  };

  renderInvoiceCards = () => {
    return this.props.invoices.length > 0 ? (
      <Col md={12} className='w-100 pr-0'>
        <Row className='w-100'>
          {this.props.invoices.map((invoice, index) => {
            return (
              <CustomCard
                card={invoice}
                key={index}
                cardType='invoices'
                handleCardClick={() => { }}
              />
            );
          })}
        </Row>
      </Col>
    ) : (
      <div className='noRecordsFound noBranchRecords noRecord '>
        <div>{Strings("NoRecordsFound")}</div>
      </div>
    );
  };

  renderPagination = () => {
    return (
      <Pagination
        activePage={this.state.activePage}
        recordsPerPage={this.state.recordsPerPage}
        numberOfPages={this.props.numberOfPages}
        onPageChange={this.onPageChange}
        dropup={true}
      />
    );
  };
  render() {
    return (
      <div className='fileContent container-fluid'>
        <Row>{this.renderSearchFilter()}</Row>
        <Row className='cardAlign'>
          {" "}
          {this.renderInvoiceCards()}
          <Col md={12} className='pt-4'>
            <div className='pull-left pl-0 pt-3 pb-2'>
              <Button
                className='buttonBorder'
                onClick={this.props.handleBackButtonClick}
              >
                {Strings("Back").toUpperCase()}
              </Button>
            </div>
            {this.props.invoices.length > 0 && (
              <Col md={10} className='pull-left pl-3 pb-2'>
                {this.renderPagination()}
              </Col>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default FileContent;
