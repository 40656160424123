import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Col, Row, Button, Table } from 'react-bootstrap';
import _ from "lodash";

//Custom UI Components
import TextFieldComponent from '../../CustomUIComponents/TextFieldComponent/TextFieldComponent';
import UserModal from '../../CustomUIComponents/UserModal/UserModal';

//Strings
import { Strings } from '../../../resources/i18n/i18n';

//Constants
import { DOWN_PAYMENT_COLLECTION_COLUMNS, EMI_COLLECTION_COLUMNS, PAYMENT_COLLECTION_TABS, SINGLE_LOAN_EMI_COLLECTION } from '../Constants';
import { FILE_CONFIGURATIONS, FILE_TYPE, TRANSFER_RECEIPT_FILE_SIZE } from '../../../constants/appConstants';

//Utils
import Utility from '../../../utils/Utility';

class PaymentCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: PAYMENT_COLLECTION_TABS[0],
            collectionData: this.props.collectionData,
            collectPayment: {
                amountToPay: "",
                paymentMode: "",
                transferReceipt: null,
                notes: "",
                loanId: null,
                amountPayOff: null,
            },
            errorMessage: "",
            showModal: false,
            uploadedTransferReceiptId: null
        }
    }

    componentDidUpdate(prevProps) {
        //Update collection data if props are changed
        if (this.props.collectionData !== prevProps.collectionData) {
            this.setState({ collectionData: this.props.collectionData });
        }
        if (this.props.errorMessage !== prevProps.errorMessage) {
            this.setState({ errorMessage: this.props.errorMessage });
        }
        if (this.props.uploadedTransferReceiptId !== prevProps.uploadedTransferReceiptId) {
            this.setState({ uploadedTransferReceiptId: this.props.uploadedTransferReceiptId });
        }
    }

    renderTableValue = (column, row) => {
        if (column.key === "action") {
            //Render radio button to select loanId
            return <input
                type='radio'
                name='loanId'
                onChange={(event) => this.onChange(event, row)}
                checked={row.id === this.state.collectPayment.loanId}
            />
        } else if (column.key === "co"
            || column.key === "lAmt"
            || column.key === "emi"
            || column.key === "dAmt"
            || column.key === "amtPayOff") {
            //Render currency representation of amount
            return Utility.getCurrencyRepresentationOfAmount(row[column.key]);
        } else if (column.key === "totalAmount") {
            //Render total amount as sum of todaysAmount and pastDue
            return row.todaysAmount + row.pastDue;
        } else if (row[column.key] !== null && row[column.key] !== undefined) {
            //Render other values
            return Strings(row[column.key]);
        } else {
            //Render NA if value is not present
            return Strings("NA")
        }
    }

    renderTable = (columns, rows) => {
        return (
            <Table hover bordered condesed='true' className='creditLimit'>
                <thead className='header'>
                    <tr>
                        {columns.map((item) => (
                            <td className='text-center'>{Strings(item.label)}</td>
                        ))}
                    </tr>
                </thead>
                {rows.map((item) => {
                    return <tr key={item.id}>
                        {columns.map((column) => {
                            return <td className='text-center'>{this.renderTableValue(column, item)}</td>
                        })}
                    </tr>
                })}
            </Table>
        )
    }


    onChange = (event, row) => {
        let collectPayment = { ...this.state.collectPayment };
        let uploadedTransferReceiptId = this.state.uploadedTransferReceiptId;
        let name = event.target.name;
        let value = event.target.value;
        if (name === "loanId") {
            collectPayment.loanId = row.id;
            collectPayment.amountPayOff = row.amtPayOff;
        } else if (name === "paymentMode") {
            collectPayment[name] = value;
            if (value === "CASH") {
                collectPayment.transferReceipt = null;
                uploadedTransferReceiptId = null;
            }
        } else if (name === "amountToPay") {
            collectPayment[name] = parseInt(value);
        } else {
            collectPayment[name] = value;
        }

        //If loanId is not selected, then set amountPayOff as amountToPay
        if (!collectPayment.loanId) {
            collectPayment.amountPayOff = this.state.collectionData.amtPayOff;
        }

        this.setState({
            collectPayment,
            uploadedTransferReceiptId,
            errorMessage: ""
        });
    }

    onFileChange = (event) => {
        this.setState({
            collectPayment: {
                ...this.state.collectPayment,
                transferReceipt: event.target.files[0],
                uploadedTransferReceiptId: null,
            },
            errorMessage: ""
        }, () => {
            if (this.state.collectPayment.transferReceipt) {
                this.uploadFile()
            }
        });
    }

    uploadFile = () => {
        let validFile = true;
        let errorMessage = "";
        let fileExtension = Utility.getFileExtension(this.state.collectPayment.transferReceipt);;

        if (this.state.collectPayment.paymentMode !== "CASH"
            && !this.state.collectPayment.transferReceipt) {
            let errorMessage = Strings("pleaseSelectRequiredFields");
            this.setState({ errorMessage });
        }
        if (
            !_.includes(
                FILE_CONFIGURATIONS.allowedExtensions,
                fileExtension
            )
        ) {
            validFile = false;
            errorMessage = Strings("fileExtensionErrorOfTranferReceipt")
        }
        if (validFile && this.state.collectPayment.transferReceipt.size > TRANSFER_RECEIPT_FILE_SIZE) {
            validFile = false;
            errorMessage = Strings("sizeIsTooLarge");
        }
        if (validFile) {
            let request = {
                transferReceipt: this.state.collectPayment.transferReceipt,
                collectionType: this.state.activeTab.key === 2
                    ? "DOWNPAYMENT"
                    : "EMI"
            }
            this.props.uploadTransferReceiptOfPaymentCollection(request)
        } else {
            this.setState({
                errorMessage,
            })
        }
    }


    getFileType = (file) => {
        if (file) {
            let fileExtension = Utility.getFileExtension(file);
            if (fileExtension === FILE_TYPE.pdf.toLowerCase()) {
                return FILE_TYPE.pdf;
            } else if (fileExtension === FILE_TYPE.jpg.toLowerCase()) {
                return FILE_TYPE.jpg;
            } else if (fileExtension === FILE_TYPE.jpeg.toLowerCase()) {
                return FILE_TYPE.jpeg;
            } else if (fileExtension === FILE_TYPE.png.toLowerCase()) {
                return FILE_TYPE.png;
            }
        }

        return null;
    };

    handleTabs = (index) => {
        //Clear data on tab change
        this.setState({
            activeTab: PAYMENT_COLLECTION_TABS[index],
            collectionData: [],
            collectPayment: {},
            uploadedTransferReceiptId: null,
            errorMessage: ""
        }, () => {
            if (this.state.activeTab.key === 2) {
                //Get downpayment details
                this.props.getDownPaymentDetails(this.renderTabsData);
            } else if (this.state.activeTab.key === 0 || this.state.activeTab.key === 1) {
                //Get EMI details
                this.props.getMinimartCollectionDetails(this.renderTabsData);
            }
        });
    };

    toggleAssignSellerModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    clearData = () => {
        this.setState({
            collectPayment: {
                amountToPay: "",
                paymentMode: "",
                transferReceipt: null,
                notes: "",
                loanId: null,
                amountPayOff: null,
                transferReceiptId: null,
            },
            uploadedTransferReceiptId: null
        })
    }
    checkMaxAmtIfFormNotFilled = () => {
        let loan = this.state.collectionData.pobld?.find(item => item.lid === this.state.collectPayment.loanId);
        if (loan) {
            if (parseInt(this.state.collectPayment.amountToPay) > loan.ma) {
                return true
            }
        }
        else {
            return false
        }
    }
    validationsToCollectPayment = () => {
        let valid = true;
        let errorMessage = "";
        //Emtpy check for specific field
        if (!this.state.collectPayment.amountToPay
            || !this.state.collectPayment.paymentMode
            || (this.state.collectPayment.paymentMode !== "CASH"
                && !this.state.collectPayment.transferReceipt)
        ) {
            valid = false;
            errorMessage = Strings("pleaseSelectRequiredFields");
        } else if (this.state.collectPayment.paymentMode !== "CASH"
            && this.state.collectPayment.transferReceipt
            && !this.state.uploadedTransferReceiptId) {
            valid = false;
            errorMessage = Strings("transferReceiptNotUploaded");
        } else if (this.state.activeTab.key === 1
            && !this.state.collectPayment.loanId) {
            valid = false;
            errorMessage = Strings("pleaseSelectLoan");
        }

        //Amount to pay should not be greater than pay off
        if (this.state.collectPayment.amountToPay
            && this.state.collectPayment.paymentMode && this.state.collectionData.pobld?.length > 0 && this.checkMaxAmtIfFormNotFilled()) {
            valid = false;
            let loan = this.state.collectionData.pobld?.find(item => item.lid === this.state.collectPayment.loanId);
            if (loan.ma > 0) {
                errorMessage = Strings("loanSpecificPayment").replace("*", loan.lid).replace("#", `$ ${Utility.getCurrencyRepresentationOfAmount(loan.ma)}`);
            } else {
                errorMessage = Strings("CannotMakePaymentForLoan").replace("$", loan.lid);
            }

        } else if ((this.state.collectPayment.loanId
            && parseInt(this.state.collectPayment.amountToPay) > parseInt(this.state.collectionData.amountPayOff))
            || parseInt(this.state.collectPayment.amountToPay) > parseInt(this.state.collectionData.amtPayOff)) {
            valid = false;
            errorMessage = Strings("amountToPayShouldNotBeGreaterThanPayOff");
        }
        this.setState({ errorMessage });
        return valid;
    }


    collectPayment = () => {
        //Validations to collect payment
        let valid = true;
        valid = this.validationsToCollectPayment();
        if (valid) {
            if (this.state.activeTab.key === 2) {
                //Collect downpayment
                this.props.collectDownPayment(this.state.collectPayment, this.clearData);
            } else {
                //Collect EMI
                this.props.collectPayment(this.state.collectPayment, this.clearData, this.toggleAssignSellerModal);
            }
        }
    }

    renderCollectPayment = () => {
        let isApplicationForNotFilled = this.state.collectionData.pobld?.length > 0 && this.state.activeTab.key === 0;
        return <Row className='textFieldComponent'>
            <Col md={6} className='mt-3 d-flex'>
                <div className='w-100'>
                    <TextFieldComponent
                        type='number'
                        placeholderTop={Strings("amountToPay")}
                        placeholder={Strings("amountToPay")}
                        handleChange={this.onChange}
                        name={"amountToPay"}
                        value={this.state.collectPayment.amountToPay}
                        onKeyDown={Utility.allowOnlyNumber}
                        required={true}
                        disabled={isApplicationForNotFilled}
                    />
                </div>
                <div className='ml-2 w-100'>
                    <TextFieldComponent
                        title=''
                        type='select'
                        placeholderTop={Strings("paymentMode")}
                        handleChange={this.onChange}
                        value={this.state.collectPayment.paymentMode}
                        multiple={false}
                        halfWidth={false}
                        name={"paymentMode"}
                        required={true}
                        disabled={isApplicationForNotFilled}
                    >
                        <option default value=''>
                            {Strings("paymentMode")}
                        </option>
                        {this.props.possiblePaymentModes?.map((singlePaymentMode) => {
                            return (
                                <option
                                    key={singlePaymentMode}
                                    value={singlePaymentMode}
                                >
                                    {Strings(singlePaymentMode)}
                                </option>
                            );
                        })}
                    </TextFieldComponent>
                </div>
            </Col>
            {this.state.collectPayment.paymentMode !== "CASH" && !isApplicationForNotFilled
                ? <Col md={6} className='mt-3'>
                    <span>{Strings("transferReceipt")}</span>
                    <span className='requiredFields'>*</span>
                    <span
                        className='d-flex'
                    >
                        {this.state.uploadedTransferReceiptId &&
                            <div
                                className='mr-2 url textColor cursorPointer'
                                onClick={this.props.viewUploadedTranferReceipt}
                            >
                                {Strings("viewReceipt")}
                            </div>}
                        <input
                            ref="fileInput"
                            type="file"
                            className="collapseButton"
                            onChange={this.onFileChange}
                        />
                        <div
                            className='url textColor cursorPointer'
                            onClick={() => this.refs.fileInput.click()}>
                            {this.state.collectPayment.transferReceipt
                                ? Strings("changeReceipt")
                                : Strings("selectReceipt")}
                        </div>


                    </span>
                </Col>
                : <Col md={6}></Col>}
            <Col md={6} className={'my-3'}>
                <span>
                    {Strings("Notes")}
                </span>
                <textarea
                    id='Notes'
                    className='w-100'
                    type='text'
                    placeholder={Strings("Notes")}
                    maxLength={"150"}
                    rows={2}
                    onChange={this.onChange}
                    value={this.state.collectPayment.notes}
                    name='notes'
                    disabled={isApplicationForNotFilled}
                />
            </Col>
            <Col className='mt-3 d-flex align-items-center'>
                <Button
                    className={'primaryModalButton buttonBackground buttonMinWidth'}
                    onClick={this.collectPayment}
                    disabled={isApplicationForNotFilled}>
                    {Strings("collectPayment")}
                </Button>
            </Col>
        </Row >
    }

    renderEMICollection = (columns, tableData) => {
        return tableData && tableData.length !== 0
            ? <div>
                <Col md={3} className='d-flex justify-content-between'>
                    <strong>{`${Strings("totalAmount")} : $${Utility.getCurrencyRepresentationOfAmount(this.state.collectionData.amtPay)}`}</strong>
                    <strong>{`${Strings("payOff")} : $${Utility.getCurrencyRepresentationOfAmount(this.state.collectionData.amtPayOff)}`}</strong>
                </Col>

                {/* Render input fields to accept data for payment */}
                {this.renderCollectPayment()}

                {/* Table to show payment collection data */}
                <Col md={12}>
                    {this.renderTable(columns, tableData)}
                </Col>
            </div>
            : <div className='noRecordsFound'>
                {Strings("NoRecordsFound")}
            </div>
    }

    renderDownpymentCollection = (columns, tableData) => {
        return tableData && tableData.length !== 0 && this.state.activeTab.key === 2
            ? <div>

                {/* Render input fields to accept data for payment */}
                {this.renderCollectPayment()}

                {/* Table to show payment collection data */}
                <Col md={12}>
                    {this.renderTable(columns, tableData)}
                </Col>
            </div>
            : <div className='noRecordsFound'>
                {Strings("NoRecordsFound")}
            </div>
    }

    renderConfirmationModal = () => {
        return <UserModal
            open={this.state.showModal}
            modalCloseOnEsc={false}
            modalCloseOnOverlayClick={false}
            modalShowCloseIcon={false}
            headerText={Strings("salesPersonAssignment")}
            modalText1={Strings("doYouWantNewLoan")}
            modalButtonOk={{
                text: Strings("Yes"),
                onClick: () => this.props.assignSellerAfterPayOff(this.toggleAssignSellerModal),
            }}
            modalButtonCancel={{
                text: Strings("No"),
                onClick: this.toggleAssignSellerModal,
            }}
            showHr={true}
            isPayment={true}
        />
    }

    selectedTabClass = () => {
        if (this.state.activeTab.key === 0) {
            return "leftCornerRadius";
        } else if (this.state.activeTab.key === 2) {
            return "rightCornerRadius";
        } else {
            return "cenderCornerRadius";
        }
    };

    //Render Method
    render() {
        return (
            <div className='loanCharts'>
                {/* Confirmation modal to assign seller after paying off the loan */}
                {this.state.showModal && this.renderConfirmationModal()}
                {/* Tabs to show in payment collection tab */}
                <Tabs
                    selectedIndex={this.state.activeTab.key}
                    onSelect={this.handleTabs}
                    selectedTabClassName={this.selectedTabClass()}
                >
                    <TabList>
                        {PAYMENT_COLLECTION_TABS.map((item) => (
                            <Tab key={item.key}>{Strings(item.value)}</Tab>
                        ))}
                    </TabList>
                    {this.state.errorMessage && <div className='errors mb-0'>{this.state.errorMessage}</div>}
                    {this.state.collectionData.pobld && this.state.activeTab.key === 0 && <div className='errors mb-0'>{Strings("PaymentCannotBeMade")}</div>}

                    {/* Tab to collect payment for all the loans of minimart*/}
                    <TabPanel>
                        {this.renderEMICollection(EMI_COLLECTION_COLUMNS, this.state.collectionData?.loans)}
                    </TabPanel>

                    {/* Tab to collect payment for single loan */}
                    <TabPanel>
                        {this.renderEMICollection(SINGLE_LOAN_EMI_COLLECTION, this.state.collectionData?.loans)}
                    </TabPanel>

                    {/* Tab to collect downpayment of loan*/}
                    <TabPanel>
                        {this.renderDownpymentCollection(DOWN_PAYMENT_COLLECTION_COLUMNS, _.isEmpty(this.state.collectionData) ? [] : [this.state.collectionData])}
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

export default PaymentCollection;