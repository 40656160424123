import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";

//Components
import MinimartDetailsPopup from "../MinimartDetails/MinimartDetailsPopup";
import CustomTableCard from "../CustomUIComponents/CustomTableCard/CustomTableCard.js";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import EditableModal from "../LoanDetails/EditableModal";

//Constants
import {
  TABLE_HEADER_LIST,
  SECOND_ROW,
  STATUS_LIST,
  SIGN_UP_REQUEST_APPROVED_USERS,
  CI_MINIMART_APPROVED_USERS,
  MINIMART_DETAILS,
} from "./Constants";
import { BRANCH } from "../MinimartDetails/Constants";
import {
  DEFAULT_DATE_FORMAT,
  CONFIGURATION_DATE_TIME_FORMAT,
  NOTES_TYPE,
  DEFAULT_VALUES,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./SignupRequestDetails.scss";

//Assets
import Defaultminimart from "../../resources/images/ic_mini_market_new.svg";
import editNote from "../../resources/images/ic_edit.svg";

class SignupRequestDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: props.errorMessage,
      errorForPopup: "",
      branchName: "",
      branch: "",
      zone: "",
      mZone: "",
      visitDay: "",
      openModal: false,
      saveClicked: false,
      branchList: this.props.branchList,
      zones: this.props.zones,
      mZones: this.props.mZones,
      expanded: [],
      rowId: "",
      isRejected: true,
      showUserModal: false,
      date: moment(new Date()).format(DEFAULT_DATE_FORMAT),
      existingId: "",
      editableMinimartDetails: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branchList, this.props.branchList)) {
      this.setState({ branchList: this.props.branchList });
    }
    if (!_.isEqual(prevProps.errorMessage, this.props.errorMessage)) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }
  }

  handleEditMinimartDetails = () => {
    this.setState({
      editableMinimartDetails: !this.state.editableMinimartDetails,
      editedDetails: this.props.emInfo ? this.props.emInfo : this.props.minimart,
      errorMessage: "",
    });
  };

  changedMinimartDetails = () => {
    let keys = Object.keys(this.props.minimart);
    let differentKeys = [];
    for (const key of keys) {
      if (
        !_.isEqual(this.props.minimart[key], this.props.emInfo[key])
      ) {
        differentKeys.push(key);
      }
    }
    return differentKeys;
  };

  renderValues = (data, minimart) => {
    let value = data[minimart.key]
    if (minimart.key === "fn") {
      value = data.fn +
        " " +
        data.ln
    } else if (!data[minimart.key]) {
      value = Strings("NA")
    }
    return value;
  }

  renderMinimartDetails = () => {
    let minimartDetails = MINIMART_DETAILS;
    return this.props.emInfo ? (
      <Row className='underlineText pb-1'>
        <Col md={6} className='borderRight'>
          <Row>
            <strong>{Strings("minimartDetailsText")}</strong>
          </Row>

          {minimartDetails.map((minimart) => {
            return (
              <div className='contentWidth pt-2' key={minimart.key}>
                <span className='titleText'>
                  {minimart.key === "fn"
                    ? Strings("fullName") + " : "
                    : Strings(minimart.label) + " : "}
                </span>
                <span
                  className={
                    this.changedMinimartDetails().includes(
                      minimart.key
                    )
                      ? "pr-0 detailText textColor"
                      : "pr-0 detailText "
                  }
                >
                  {this.renderValues(this.props.minimart, minimart)}
                </span>
              </div>
            );
          })}
        </Col>
        <Col md={6}>
          <Row>
            <strong>{Strings("oldMinimartDetails")}</strong>
            <div className='pencil mx-1 px-1'>
              <img
                src={editNote}
                className={"editIcon m-0"}
                alt={"edit"}
                title={Strings("editOldMinimartDetails")}
                onClick={this.handleEditMinimartDetails}
              />
            </div>
          </Row>
          {minimartDetails.map((minimart) => {
            return (
              <div className='contentWidth pt-2' key={minimart.key}>
                <span className='titleText'>
                  {minimart.key === "fn"
                    ? Strings("fullName") + " : "
                    : Strings(minimart.label) + " : "}
                </span>
                <span className='pr-0 detailText '>
                  {this.renderValues(this.props.emInfo, minimart)}

                </span>
              </div>
            );
          })}
        </Col>
      </Row>
    ) : (
      <>
        <Row className='underlineText pb-1'>
          {Strings("minimartDetailsText")}
        </Row>
        <Row>
          {minimartDetails.map((minimart) => {
            return (
              <Col md={4} className='px-0' key={minimart.key}>
                <div className='contentWidth pt-2'>
                  <span className='titleText'>
                    {Strings(minimart.label) + " : "}
                  </span>
                  <span className='pr-0 detailText '>
                    {this.props.isSignupRequest
                      && SIGN_UP_REQUEST_APPROVED_USERS.includes(this.props.loggedInUser.role)
                      && this.props.minimart.st === STATUS_LIST.PENDING
                      && minimart.key === "cdid" ?
                      <>
                        {this.renderValues(this.props.minimart, minimart)}
                        <img
                          src={editNote}
                          className={"editIcon m-0"}
                          alt={"edit"}
                          title={Strings("editCedula")}
                          onClick={this.handleEditMinimartDetails}
                        />
                      </>
                      : this.renderValues(this.props.minimart, minimart)}

                  </span>
                </div>
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  handleEditClick = () => {
    this.props.getBranchList();
    this.props.getZonesByBranch(this.state.branch);
    this.setState({ openModal: true, errorMessage: "" });
  };

  renderAssociation = () => {
    //NOSONAR
    return (
      <>
        <Row className={"underlineText pt-3"}>
          <strong>{Strings("Association")}</strong>
          <span className='mandatoryFields'>*</span>
          {this.props.minimart
            && ((this.props.minimart.st === STATUS_LIST.PENDING
              && SIGN_UP_REQUEST_APPROVED_USERS.includes(this.props.loggedInUser.role))
              || !this.props.isSignupRequest) && (
              <div className='pencil mx-1 px-1'>
                <img
                  src={editNote}
                  className={"editIcon m-0"}
                  alt={"edit"}
                  title={Strings("EditAssociation")}
                  onClick={this.handleEditClick}
                />
              </div>
            )}
        </Row>
        <Row>
          <Col md={4} className='px-0'>
            <div className='contentWidth'>
              <span className='titleText'>
                {Strings("associatedBranch")}
              </span>
              &nbsp;:&nbsp;
              <span className='colorText'>
                {this.props.minimart &&
                  this.props.minimart.st === STATUS_LIST.APPROVED
                  ? this.props.minimart.brn //NOSONAR
                    ? this.props.minimart.brn
                    : Strings("NA")
                  : this.state.branchName //NOSONAR
                    ? this.state.branchName
                    : Strings("NA")}
              </span>
            </div>
          </Col>
          <Col md={4} className='px-0'>
            <div className='contentWidth'>
              <span className='titleText'>
                {Strings("associatedZone")}
              </span>
              &nbsp;:&nbsp;
              <span className='colorText'>
                {this.props.minimart &&
                  this.props.minimart.st === STATUS_LIST.APPROVED
                  ? this.props.minimart.zn //NOSONAR
                    ? this.props.minimart.zn
                    : Strings("NA")
                  : this.state.zone //NOSONAR
                    ? this.state.zone
                    : Strings("NA")}
              </span>
            </div>
          </Col>
          <Col md={4} className='px-0'>
            <div className='contentWidth'>
              <span className='titleText'>{Strings("mzone")}</span>
              &nbsp;:&nbsp;
              <span className='colorText'>
                {this.props.minimart &&
                  this.props.minimart.st === STATUS_LIST.APPROVED
                  ? this.props.minimart.mzn //NOSONAR
                    ? this.props.minimart.mzn
                    : Strings("NA")
                  : this.state.mZone //NOSONAR
                    ? this.state.mZone
                    : Strings("NA")}
              </span>
            </div>
          </Col>
          <Col md={4} className='px-0'>
            <div className='contentWidth'>
              <span className='titleText'>{Strings("visitDay")}</span>
              &nbsp;:&nbsp;
              <span className='colorText'>
                {this.props.minimart &&
                  this.props.minimart.st === STATUS_LIST.APPROVED
                  ? this.props.minimart.vd //NOSONAR
                    ? Strings(this.props.minimart.vd)
                    : Strings("NA")
                  : this.state.visitDay //NOSONAR
                    ? Strings(this.state.visitDay)
                    : Strings("NA")}
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  isValidApproveData = () => {
    return !(
      this.state.branchName === "" ||
      this.state.zone === "" ||
      this.state.mZone === "" ||
      this.state.visitDay === ""
    );
  };

  assignSalesPerson = () => {
    this.setState({
      showUserModal: true,
    });
  };

  approvedClick = () => {
    if (this.isValidApproveData()) {
      this.toggleModal(false);
    } else {
      this.setState({
        errorMessage: Strings("pleaseSelectRequiredFields"),
      });
    }
  };
  rejectedClick = () => {
    this.toggleModal(true);
  };
  existingClick = () => {
    if (
      this.state.rowId === "" &&
      this.props.emInfo &&
      this.props.emInfo.emid === ""
    ) {
      this.setState({
        errorMessage: Strings("ExistingMinimart"),
      });
    } else {
      if (this.isValidApproveData()) {
        this.props.saveMinimart(
          {
            id: this.props.selectedMinimart.id,
            mid: this.state.rowId || this.props.emInfo?.emid,
            st: STATUS_LIST.EXISTING,
            br: this.state.branch,
            zn: this.state.zone,
            mzn: this.state.mZone,
            vd: this.state.visitDay,
          },
          () => {
            this.setState({ rowId: "" });
          },
          (errorMessage) => {
            this.setState({ errorMessage: errorMessage });
          }
        );
      } else {
        this.props.saveMinimart(
          {
            id: this.props.selectedMinimart.id,
            mid: this.state.rowId || this.props.emInfo?.emid,
            st: STATUS_LIST.EXISTING,
          },
          () => {
            this.setState({ rowId: "" });
          },
          (errorMessage) => {
            this.setState({ errorMessage: errorMessage });
          }
        );
      }

    }
  };

  stopAssignment = () => {
    this.props.stopAssignment(
      this.props.selectedMinimart.id,
      (errorMessage) => {
        this.setState({ errorMessage });
      }
    );
  };

  renderActionButton = () => {
    let disableApproveButton = false;
    let disableExistingButton = false;
    let disableRejectButton = false;
    if (this.props.isSignupRequest) {
      if (!SIGN_UP_REQUEST_APPROVED_USERS.includes(this.props.loggedInUser.role)) {
        disableApproveButton = true;
        disableExistingButton = true;
        disableRejectButton = true;
      } else if (this.state.rowId !== "") {
        disableApproveButton = true;
        disableRejectButton = true;
      } else if (this.props.emInfo || this.props.isExistingMinimartCode) {
        disableApproveButton = true;
      } else if (!this.props.emInfo && this.props.minimartList.length === 0) {
        disableExistingButton = true;
      }
    } else {
      if (!CI_MINIMART_APPROVED_USERS.includes(this.props.loggedInUser.role)) {
        disableApproveButton = true;
        disableExistingButton = true;
        disableRejectButton = true;
      } else if (this.props.minimartList.length === 0) {
        disableExistingButton = true;
      }
    }

    return (
      <Row className='py-2  '>
        <Col md={1}>
          <Button
            className='buttonBorder'
            onClick={this.props.goBack}
          >
            {Strings("Back").toUpperCase()}
          </Button>
        </Col>
        {
          (this.props?.minimart?.st === STATUS_LIST.PENDING ||
            !this.props.isSignupRequest) ? (
            <>
              <Col md={{ span: 1, offset: 8 }}>
                {!disableApproveButton && (
                  <Button
                    title={Strings("Approve")}
                    className='buttonBackground'
                    onClick={this.approvedClick}
                  >
                    {Strings("Approve")}
                  </Button>
                )}
              </Col>
              <Col md={1}>
                {!disableRejectButton && (
                  <Button
                    title={Strings("Reject")}
                    className='rejectButtonBackground'
                    onClick={this.rejectedClick}
                  >
                    {Strings("Reject")}
                  </Button>
                )}
              </Col>
              <Col md={1}>
                {!disableExistingButton && (
                  <Button
                    title={Strings("existing")}
                    className='buttonBorder'
                    onClick={this.existingClick}
                  >
                    {Strings("existing")}
                  </Button>
                )}
              </Col>
            </>
          ) :
            (this.props.isSignupRequest &&//NOSONAR
              this.props?.minimart?.st === STATUS_LIST.APPROVED) ? (
              <>
                <Col md={{ span: 2, offset: 9 }}>
                  <Button
                    title={Strings("StopAssignment")}
                    className='buttonBackground'
                    onClick={this.stopAssignment}
                    disabled={!this.props.minimart.ia}
                  >
                    {Strings("StopAssignment")}
                  </Button>
                </Col>
              </>
            ) : null}
      </Row>
    );
  };

  handleModalInputChange = (event) => {
    const name = event.target.name;
    const value = DEFAULT_VALUES.includes(event.target.value)
      ? ""
      : event.target.value;
    let stateData = {};
    if (name === BRANCH) {
      stateData.branch = value;
      stateData.branchName =
        value ? this.props.branchList[
          this.props.branchList.findIndex(
            (branch) => branch.id === parseInt(value)
          )
        ].name : "";
      stateData.zone = "";
      stateData.mZone = "";
    } else {
      stateData[name] = value;
    }
    this.setState(
      {
        ...stateData,
        errorForPopup: "",
        sp: "",
        msg: "",
      },
      () => {
        if (name === BRANCH) {
          this.props.getZonesByBranch(this.state.branch);
        } else if (this.state.visitDay !== "") {
          if (
            this.state.branch === "" ||
            this.state.zone === "" ||
            this.state.mZone === ""
          ) {
            this.setState({
              errorForPopup: Strings("pleaseSelectRequiredFields"),
            });
          } else {
            this.getAssignedMinimartUserList({
              br: this.state.branch,
              zn: this.state.zone,
              mzn: this.state.mZone,
              vd: this.state.visitDay,
            });
          }
        }
      }
    );
  };

  getAssignedMinimartUserList = (requestData) => {
    this.props.getAssignedMinimartUserList(
      requestData,
      (data) => {
        this.setState({ sp: data.sp, msg: data.ms });
      },
      (errorMessage) => {
        this.setState({ errorMessage: errorMessage || "" });
      }
    );
  };

  handleRouteChange = () => {
    if (this.isValidApproveData()) {
      this.setState({ openModal: false, saveClicked: true });
    } else {
      this.setState({
        errorForPopup: Strings("pleaseSelectRequiredFields"),
      });
    }
  };

  renderMinimartDetailsPopup = () => {
    return (
      this.props.branchList &&
      this.props.branchList.length > 0 && (
        <MinimartDetailsPopup
          openModal={this.state.openModal}
          closeModal={this.closeModal}
          handleModalInputChange={this.handleModalInputChange}
          handleRouteChange={this.handleRouteChange}
          sp={this.state.sp}
          msg={this.state.msg}
          branchList={this.props.branchList}
          zones={this.props.zones}
          mZones={this.props.mZones}
          actionBtnDisabled={false}
          errorForPopup={this.state.errorForPopup}
          branch={this.state.branch}
          zone={this.state.zone}
          mZone={this.state.mZone}
          visitDay={this.state.visitDay}
          // isSignupRequest={true}
          isAssociationEdit={true}
          isMergeMinimart={false}
          yesBtnText={"Yes"}
          cancelBtnText={"Cancel"}
          modalHeaderText={"minimartDetailsText"}
        />
      )
    );
  };

  closeModal = () => {
    this.setState({
      openModal: false,
      errorForPopup: "",
    });
    if (this.state.saveClicked === false || !this.isValidApproveData()) {
      this.setState({
        branch: "",
        branchName: "",
        zone: "",
        mZone: "",
        visitDay: "",
        sp: "",
        msg: ""
      })
    }

  };
  handleExpandClick = (id) => {
    let expandIndexId = this.state.expanded;
    const index = expandIndexId.indexOf(id);
    expandIndexId.includes(id)
      ? expandIndexId.splice(index, 1)
      : expandIndexId.push(id);
    this.setState({
      expanded: expandIndexId,
    });
  };

  rowSelect = (mid) => {
    this.setState({
      rowId: this.state.rowId === mid ? "" : mid,
      errorMessage: "",
    });
  };

  renderCustomDataGrid = () => {
    return (
      <div>
        <CustomTableCard
          rows={this.props.minimartList}
          columns={TABLE_HEADER_LIST}
          secondRowColumns={SECOND_ROW}
          isSummary={false}
          isExpandable={true}
          expanded={this.state.expanded}
          handleExpandClick={this.handleExpandClick}
          callBack={this.rowSelect}
          isSelectedRow={this.state.rowId}
          isSignupRequest={true}
        />
      </div>
    );
  };

  toggleModal = (isRejected = true) => {
    this.setState({
      notesModal: !this.state.notesModal,
      isRejected,
      errorMessage: "",
    });
  };

  addNote = (notes) => {
    if (notes) {
      const request = {
        refId: this.props.selectedMinimart.id,
        note: notes,
        type: this.props.isSignupRequest
          ? NOTES_TYPE.SIGN_UP_REQUEST
          : NOTES_TYPE.CI_MINIMART_APPROVE,
      };
      this.props.addNote(
        request,
        (noteId) => {
          if (this.state.isRejected) {
            this.props.saveMinimart(
              {
                id: this.props.selectedMinimart.id,
                nid: noteId,
                st: STATUS_LIST.REJECTED,
              },
              this.toggleModal,
              (errorMessage) => {
                this.setState({ errorMessage });
              }
            );
          } else {
            this.props.saveMinimart(
              {
                id: this.props.selectedMinimart.id,
                nid: noteId,
                br: this.state.branch,
                zn: this.state.zone,
                mzn: this.state.mZone,
                vd: this.state.visitDay,
                st: STATUS_LIST.APPROVED,
              },
              (mid) => {
                this.props.isSignupRequest &&
                  this.setState(
                    { existingId: mid },
                    this.assignSalesPerson
                  );
                this.toggleModal();
              },
              (errorMessage) => {
                this.setState({ errorMessage });
              }
            );
          }
        },
        (errorMessage) => {
          this.setState({ errorMessage });
        }
      );
    } else {
      this.props.saveMinimart(
        {
          id: this.props.selectedMinimart.id,

          br: this.state.branch,
          zn: this.state.zone,
          mzn: this.state.mZone,
          vd: this.state.visitDay,
          st: STATUS_LIST.APPROVED,
        },
        (mid) => {
          this.setState({ existingId: mid }, this.assignSalesPerson);
          this.toggleModal();
        },
        (errorMessage) => {
          this.setState({ errorMessage });
        }
      );
    }
  };

  renderNotesModal = () => {
    return (
      <NotesModal
        open={this.state.notesModal}
        modalHeader={Strings("Notes")}
        toggleModal={this.toggleModal}
        addNote={this.addNote}
        isNotesCompulsory={this.state.isRejected}
        errorMessage={this.state.errorMessage}
      />
    );
  };

  onYesClick = () => {
    this.props.assignSalesPerson(
      {
        mid: this.state.existingId,
        ad: Utility.convertDateIntoMiliSeconds(this.state.date),
        id: this.props.selectedMinimart.id,
      },
      () => {
        this.setState({
          showUserModal: false,
        });
      },
      (errorMessage) => {
        this.setState({ errorMessage });
      }
    );
  };

  onCancelClick = () => {
    this.setState(
      {
        showUserModal: false,
        errorMessage: "",
      },
      this.props.gotoSignUpRequest
    );
  };

  onDaySelection = (dayObject) => {
    let date = Utility.daySelectionForSellerAssignment(dayObject.day)
    this.setState({ date });
  };

  renderConfirmationModal = () => {
    return (
      <UserModal
        open={this.state.showUserModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("salesPersonAssignment")}
        modalText1={Strings("approvedMinimartSalespersonAssignment")}
        modalButtonOk={{
          text: Strings("Set"),
          onClick: this.onYesClick,
        }}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.onCancelClick,
        }}
        showHr={true}
        handleRadioChange={this.onDaySelection}
        note={Strings("visitNote")}
        errorMessage={this.state.errorMessage}
      />
    );
  };
  renderMinimartNotes = () => {
    return (
      <>
        <hr />
        <div className='outerBorder notesHeader'>
          <div className='my-2'>{Strings("Notes")}</div>
          <div className='notes'>
            <div key={this.props.note.id} className='innerBox px-2'>
              <span className='author'>
                {Strings("author")}:&nbsp;
                {this.props.note.author}
              </span>
              <span className='date pl-3'>
                {moment(this.props.note.ca).format(
                  CONFIGURATION_DATE_TIME_FORMAT
                )}
              </span>

              <div className='noteColor'>{this.props.note.note}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  onChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      editedDetails: {
        ...this.state.editedDetails,
        [name]: value,
      },
      errorMessage: "",
    });
  };

  updateMinimartDetails = () => {
    this.props.updateMinimartDetails(
      this.state.editedDetails,
      this.handleEditMinimartDetails
    );
  };
  editScalaMinimartRequest = () => {
    if (this.state.editedDetails.cdid.length !== 11) {
      this.setState({ errorMessage: Strings("pleaseEnterRequiredFields") })
    } else if (this.props.minimart.cdid === this.state.editedDetails.cdid) {
      this.setState({ errorMessage: Strings("sameCedulaError") })
    } else {
      let reqObject = { oci: this.state.editedDetails.cdid }
      this.props.editScalaMinimartRequest(
        reqObject,
        this.handleEditMinimartDetails
      );
    }
  };

  renderEditableModal = () => {
    let editableData = [];
    if (this.props.emInfo) {
      editableData = [
        {
          title: Strings("MiniMarketName"),
          value: this.state.editedDetails.mn,
          isMandatoryField: false,
          name: "mn",
          type: "text",
          md: 6,
        },
        {
          title: Strings("ownerName"),
          value: this.state.editedDetails.on,
          isMandatoryField: false,
          name: "on",
          type: "text",
          md: 6,
        },
        {
          title: Strings("code"),
          value: this.state.editedDetails.mc,
          isMandatoryField: false,
          name: "mc",
          type: "text",
          md: 6,
          disabled: true,
        },
        {
          title: Strings("ownerCedulaNumber"),
          value: this.state.editedDetails.cdid,
          isMandatoryField: false,
          name: "cdid",
          type: "text",
          md: 6,
          disabled: true,
          maxLength: 11
        },
        {
          title: Strings("PhoneNumber"),
          value: this.state.editedDetails.tp,
          isMandatoryField: false,
          name: "tp",
          type: "text",
          md: 6,
          maxLength: 10
        },
        {
          title: Strings("OwnerMobileNumber"),
          value: this.state.editedDetails.op,
          isMandatoryField: false,
          name: "op",
          type: "text",
          md: 6,
          maxLength: 10
        },
        {
          title: Strings("City"),
          value: this.state.editedDetails.ct,
          isMandatoryField: false,
          name: "ct",
          type: "text",
          md: 6,
        },
        {
          title: Strings("Sector"),
          value: this.state.editedDetails.scr,
          isMandatoryField: false,
          name: "scr",
          type: "text",
          md: 6,
        },
        {
          title: Strings("Street"),
          value: this.state.editedDetails.srt,
          isMandatoryField: false,
          name: "srt",
          type: "text",
          md: 6,
        },
        {
          title: Strings("number"),
          value: this.state.editedDetails.no,
          isMandatoryField: false,
          name: "no",
          type: "text",
          md: 6,
        },
        {
          title: Strings("Reference"),
          value: this.state.editedDetails.ref,
          isMandatoryField: false,
          name: "ref",
          type: "text",
          md: 6,

        },
      ];
    }
    else {
      editableData = [{
        title: Strings("ownerCedulaNumber"),
        value: this.state.editedDetails.cdid,
        isMandatoryField: false,
        name: "cdid",
        type: "text",
        md: 6,
        maxLength: 11
      }]
    }
    return (
      <EditableModal
        open={this.state.editableMinimartDetails}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("EditMinimartDetails")}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.handleEditMinimartDetails,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.SubmitMinimartDetails,
        }}
        onChange={this.onChange}
        editableData={editableData}
        SubmitMinimartDetails={this.props.emInfo ? this.updateMinimartDetails : this.editScalaMinimartRequest}
        errorMessage={this.state.errorMessage}
      />
    );
  };

  renderWarnings = () => {
    const mid = this.state.rowId || this.props.emInfo?.emid;
    return (
      <>
        {this.state.errorMessage && (
          <Row className=' errorText'>
            {this.state.errorMessage === Strings("BRANCH_ZONE_MAPPING_NOT_PRESENT") ?
              this.state.errorMessage.replace("$", mid) : this.state.errorMessage}
          </Row>
        )}
        {this.props.emInfo && (
          <>
            <Row className=' warningText'>
              {Strings("scalaRegistrationWarning")}
            </Row>
            {this.props.emInfo.tp && (
              <Row className=' warningText'>
                {`${Strings("tempPasswordSendToMobileNumber")} ${this.props.emInfo.op}`}
              </Row>
            )}
          </>
        )}
        {this.props.blacklisted && (
          <Row className=' warningText'>
            {Strings("OWNER_OF_MINIMART_IS_BLACKLISTED")}
          </Row>
        )}
        {this.props.deactivated && (
          <Row className=' warningText mb-3'>
            {Strings("OWNER_OF_MINIMART_IS_DEACTIVATED")}
          </Row>
        )}
      </>
    )
  }

  renderMetaWarnings = () => {
    return (
      <>
        {this.props.metaErrors && (
          <>
            <Col md={12} className='px-0 subHeadingText'>
              {Strings("minimartOwnerVerification")}
            </Col>
            {this.props.metaErrors?.map((reason) => {
              return (
                <Col md={12} className='warningText pl-0' key={reason.cd}>
                  {Strings(reason.msg)}
                </Col>
              );
            })}
          </>
        )}
        {this.props.isNameMismatched && (
          <Col md={12} className='warningText pl-0'>
            {Strings("nameMismatchWarning")}
          </Col>
        )}
      </>
    )
  }

  renderMinimartDetailsSection = () => {
    return (
      <Col md={11} className='pr-0 pl-1'>
        {this.renderMetaWarnings()}
        {this.renderMinimartDetails()}
        {!this.props.emInfo && this.renderAssociation()}
      </Col>
    )
  }

  render() {
    return (
      <>
        {/* Render Modals and Popups */}
        {this.state.editableMinimartDetails &&
          this.renderEditableModal()}
        {this.state.openModal && this.renderMinimartDetailsPopup()}
        {this.state.notesModal && this.renderNotesModal()}
        {this.state.showUserModal && this.renderConfirmationModal()}

        {/* Actual UI */}
        <div className='signUpMinimartDetails container-fluid ListContainer pb-2'>

          {/* Renders general warnings on the top section before the minimart logo */}
          {this.renderWarnings()}

          {/* Render Minimart Icon, Status, Meta warnings, minimart details and associations */}
          <Row className='primaryDetails'>
            {this.props.minimart ? (
              <>
                <Col md={1} className='p-0'>
                  <div className='imageplaceholder justify-center display-flex'>
                    <img src={Defaultminimart} alt='minimart' />
                  </div>
                  {this.props.minimart && (
                    <div
                      className='gradeText justify-center display-flex'
                      title={Strings("MinimartStatus")}
                    >
                      <span title={Strings("MinimartStatus")}>
                        {Strings(this.props.minimart.st)}
                      </span>
                    </div>
                  )}
                </Col>
                {this.renderMinimartDetailsSection()}

              </>
            ) : (
              <div className='noRecordsFound noBranchRecords'>
                {Strings("NoRecordsFound")}
              </div>
            )}
          </Row>

          {/* Notes section */}
          {this.props.note && this.renderMinimartNotes()}
          {this.props.minimart && <hr />}

          {/* New minimart matches with any old minimart, 
              those old minimarts get displayed here 
              and existing minimart can be selected. */}
          {this.props.minimart &&
            (this.props.minimart.st === STATUS_LIST.PENDING ||
              !this.props.isSignupRequest) &&
            !this.props.emInfo && (
              <>
                <Row className='pt-1'>
                  <Col md={12}>
                    <span className='subHeading'>
                      {Strings("matchingMinimartDetails")}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    {this.props.minimartList.length > 0 ? (
                      this.renderCustomDataGrid()
                    ) : (
                      <div className='noRecordsFound noBranchRecords'>
                        {Strings("NoRecordsFound")}
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            )}
          {this.renderActionButton()}
        </div>
      </>
    );
  }
}

export default SignupRequestDetails;
