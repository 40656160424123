import React from "react";
import { Row, Col, Button, Collapse } from "react-bootstrap";
import moment from "moment";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import _ from "lodash";
//Components
import SearchFilter from "../CustomUIComponents/ReportFilters/ReportFilters";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomTableCard from "../CustomUIComponents/CustomTableCard/CustomTableCard.js";

//Constants
import {
  SECOND_LEVEL_TABLE_COLUMNS,
  LIST_SECOND_REPORTS,
  SECOND_LEVEL_SUM_COLUMNS,
  PROVISION,
  LOAN_STATUS,
  DEFAULT_SELECTION,
  ROUTE_DAYS,
  LOAN_PORTFOLIO_SECOND_ROW,
} from "../GeneralReports/Constants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { GET_DEFAULT_ZONE_SELECTION } from "../BranchList/BranchListConstants";
import {
  DEFAULT_DATE_FORMAT,
  LOAN_TYPE,
  MINIMART_RATING,
  SPECIAL,
  SPECIAL_MESSENGER_PRESENTATION,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DOWNLOAD_FILE_TYPE,
  REPORTS_TAB_KEY_CONST,
  DEFAULT_DURATION,
  USER_ROLES,
  INVOICE_DATE_FORMAT,
  SEARCH_FILTER_PAGES,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Assets
import pageDividerOpened from "../../resources/images/ic_page_divider_arrow.svg";
import pageDividerClosed from "../../resources/images/ic_page_divider_arrow_close.svg";
import CSVdownload from "../../resources/images/ic_download_csv.svg";
import PDFdownload from "../../resources/images/ic_download_pdf.svg";
import { GET_DEFAULT_MESSENGER_ZONE_SELECTION } from "../BranchList/BranchListConstants.js";

class SecondLevelReports extends React.Component {
  constructor(props) {
    //NOSONAR
    super(props);
    let fromDate, toDate;
    if (props.user === REPORTS_TAB_KEY_CONST.WEB_COLLECTIONS) {
      fromDate = props.reports.cd
      toDate = props.reports.cd
    }
    else if (props.user !== REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO || (props.isTwoStepAction && props.searchPage === SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT)) {
      fromDate = props.flrSearchFilter.fd
      toDate = props.flrSearchFilter.td
    }
    else {
      fromDate = props.searchFilter.fd
      toDate = props.searchFilter.td
    }

    this._columns = SECOND_LEVEL_TABLE_COLUMNS[this.props.user];
    this.__Sumcolumns = SECOND_LEVEL_SUM_COLUMNS[this.props.user];
    this.state = {
      user: props.user,
      isFilterDivExpanded: true,
      fromDate: fromDate,
      toDate: toDate,
      apiUrl: LIST_SECOND_REPORTS[this.props.user].url,
      sumUrl:
        this.props.user !== REPORTS_TAB_KEY_CONST.SALES &&
          this.props.user !== REPORTS_TAB_KEY_CONST.SPECIAL_SALES
          ? LIST_SECOND_REPORTS[this.props.user].sumUrl
          : "",
      downLoadUrl: LIST_SECOND_REPORTS[this.props.user].downloadUrl,
      LOAN_PORTFOLIO: {
        fromAmountDisbursed: props.searchFilter.fad,
        toAmountDisbursed: props.searchFilter.tad,
        branch: props.isTwoStepAction
          ? props.flrSearchFilter.br
          : props.searchFilter.br,
        type: props.isTwoStepAction
          ? props.flrSearchFilter.ty
          : props.searchFilter.ty,
        status: props.searchFilter.st,
        zone: props.isTwoStepAction
          ? props.flrSearchFilter.zn
          : props.searchFilter.zn,
        messengerZone: props.isTwoStepAction
          ? props.flrSearchFilter.mzn
          : props.searchFilter.mzn,
        minimartName: props.isTwoStepAction
          ? props.reports?.mn
          : props.searchFilter.mn,
        provision: props.searchFilter.pr,
        ciCode: props.searchFilter.code,
        loanId: props.searchFilter.lid,
        matricesId: props.searchFilter.mid,
        userId: props.searchFilter.uid, // supervisor/ sales moderator
      },
      SALES: {
        minimartName: "",
        salesPerson: "",
        status: "",
      },
      SPECIAL_SALES: {
        minimartName: "",
        salesPerson: "",
      },
      COLLECTIONS: {
        minimartName: "",
        messenger: "",
        visitDays: "",
      },
      WEB_COLLECTIONS: {
        minimartName: "",
        visitDay: "",
      },
      WALLET: {
        fromDate: props.searchFilter.fd,
        toDate: props.searchFilter.td,
        applicationId: props.searchFilter.appId,
        loanId: props.searchFilter.lid,
        matricesId: props.isTwoStepAction
          ? props.reports?.mid
          : props.searchFilter.mid,
        dayInArrears: props.searchFilter.dal,
      },
      activePage: props.searchFilter.offset,
      recordsPerPage: props.searchFilter.limit,
      zones: props.zones,
      mZones: props.mZones,
      branches: props.branches,
      errorMessage: "",
      users: props.users,
      expanded: [],
    };
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    this.scrollToTop();
    if (this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO) {
      this.props.getZonesByBranch(this.state.LOAN_PORTFOLIO.branch);
      this.props.getBranchList();
      this.props.getUserList({
        filterRole: USER_ROLES.salesModerator,
      });
    }
    this.handleClickSearchFilter(false);
  }

  static getDerivedStateFromProps(props, state) {
    let derivedState = { ...state };
    props.loan &&
      props.rows.forEach((row) => {
        let index = [];
        if (props.loan === row.id) {
          index.push(row.id);
          derivedState = { ...derivedState, expanded: index };
        }
      });
    return derivedState;
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }
  }

  handleSearchChange = (key, event) => {
    let value = event.target.value;
    if (DEFAULT_SELECTION.includes(value)) {
      value = "";
    }

    this.setState({
      [this.state.user]: {
        ...this.state[this.state.user],
        [key]: value,
      },
    });
  };

  handleBranchChange = (_key, event) => {
    let value = event.target.value;
    if (DEFAULT_SELECTION.includes(value)) {
      value = "";
    }
    this.setState(
      {
        [this.state.user]: {
          ...this.state[this.state.user],
          branch: value,
          zone: "",
          messengerZone: "",
        },
      },
      () => {
        this.props.getZonesByBranch(this.state.LOAN_PORTFOLIO.branch);
      }
    );
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.fromDate)) {
          DateValue =
            moment(dateMoment).diff(this.state.fromDate, "days") === 0
              ? this.state.fromDate
              : this.state.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        if (dateMoment.isAfter(new Date())) {
          DateValue = new Date();
        }
        break;
      default:
    }
    this.setState({
      [key]: DateValue,
    });
  };

  deleteEmptyKeys = (searchObject) => {
    Object.keys(searchObject).forEach((key) => {
      if (searchObject[key] === "" || searchObject[key] === 0) {
        delete searchObject[key];
      }
    });
  };

  handleClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    let searchObject = { ...this.state[this.state.user] };
    searchObject.fromDate = this.state.fromDate;
    searchObject.toDate = this.state.toDate;
    const pagination = {
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    };

    // Route visit days

    if (this.validRange(searchObject)) {
      this.deleteEmptyKeys(searchObject);
      this.props.handleSearchFilter(
        this.state.apiUrl,
        this.state.sumUrl,
        { ...searchObject },
        { ...pagination },
      );
    }
  };

  onPageChange = ({ page, pageLength }) => {
    this.props.setRowClickAction(null, null);

    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
        expanded: [],
      },
      () => this.handleClickSearchFilter(false)
    );
  };

  handleOnClickResetFilter = () => {
    this.props.setRowClickAction(null, null);

    let keys = Object.keys({ ...this.state[this.state.user] });
    let tempObj = {};
    keys.map((key) => {
      return (tempObj[key] = ""); //NOSONAR
    });
    this.setState(
      {
        fromDate: DEFAULT_DURATION.fromDate,
        toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
        [this.state.user]: { ...tempObj },
        expanded: [],
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
      () => {
        this.props.setTwoStepAction(false);
        this.props.getZonesByBranch();
        this.handleClickSearchFilter(true);
      }
    );
  };

  renderHeader = () => {
    return (
      <Row className='py-3'>
        {this.props.userInfo !== null && <Col md={11} className='pr-0 renderHeaderUserDetails'>
          <Row className='alignItemCenter'>
            <Col md={3} className='alignCenter'>
              <span className='titleText'>
                {Strings("Name")} &nbsp;:&nbsp;
              </span>
              &nbsp;&nbsp;
              <span
                className='colorText'
                title={
                  this.props.userInfo.type &&
                    this.props.userInfo.type === SPECIAL
                    ? this.props.userInfo.firstName +
                    " " +
                    this.props.userInfo.lastName +
                    " " +
                    SPECIAL_MESSENGER_PRESENTATION
                    : this.props.userInfo.firstName +
                    " " +
                    this.props.userInfo.lastName
                }
              >
                {this.props.userInfo.type &&
                  this.props.userInfo.type === SPECIAL
                  ? SHORT_SPECIAL_MESSENGER_PRESENTATION +
                  " " +
                  this.props.userInfo.firstName +
                  " " +
                  this.props.userInfo.lastName
                  : this.props.userInfo.firstName +
                  " " +
                  this.props.userInfo.lastName}
              </span>
            </Col>
            <Col
              md={{ span: 8, offset: 1 }}
              className='alignCenter d-inline-flex'
            >
              <span className='titleText'>
                {Strings("Branch")}&nbsp;:&nbsp;
              </span>
              &nbsp;&nbsp;
              <span
                className='colorText branchName'
                title={this.props.userInfo.branch}
              >
                {this.props.userInfo.branch.map((br, index) =>
                  +index !== 0 ? ", " + br : br
                )}
              </span>
            </Col>
          </Row>
          {(this.props.userInfo.role.role !== "SALES_PERSON" ||
            this.props.userInfo.type !== "SPECIAL") && (
              <Row>
                <Col
                  md={{ span: 2, offset: 1 }}
                  className='text-left  px-0'
                >
                  <Rater
                    id={this.props.userInfo.userId}
                    title={this.props.userInfo.rating}
                    rating={this.props.userInfo.rating}
                    total={5}
                    interactive={false}
                    style={{
                      fontSize: 15 + "px",
                      color: "#3ea853",
                    }}
                  />
                  {this.props.user === REPORTS_TAB_KEY_CONST.SALES ||
                    this.props.user ===
                    REPORTS_TAB_KEY_CONST.SPECIAL_SALES ? (
                    <span className='titleText'>
                      ({MINIMART_RATING[this.props.userInfo.mRat]})
                    </span>
                  ) : null}
                </Col>
                <Col
                  md={{ span: 8, offset: 1 }}
                  className='pr-0 text-break'
                >
                  <span className='titleText'>
                    {Strings("Zones")}
                  </span>
                  &nbsp;:&nbsp;
                  {this.props.userInfo.zones &&
                    this.props.userInfo.zones.length > 0 ? (
                    <span className='colorText'>
                      {this.props.userInfo.zones.map((zone) => {
                        return (
                          <span className='px-1' key={zone}>
                            {zone}
                          </span>
                        );
                      })}
                    </span>
                  ) : (
                    Strings("NA")
                  )}
                </Col>
              </Row>
            )}
        </Col>}
        <Col
          md={{ span: 2 }}
          className='pull-right ml-auto alignDownloadButtons renderHeaderButtons'
        >
          {" "}
          <div>
            <Button
              className='downloadButtons mr-3'
              onClick={() => {
                this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
              }}
            >
              <img src={PDFdownload} alt='' />
            </Button>
            <Button
              className='downloadButtons'
              onClick={() => {
                this.downloadFile(DOWNLOAD_FILE_TYPE.CSV);
              }}
            >
              <img src={CSVdownload} alt='' />
            </Button>
          </div>
        </Col>
      </Row>
    )

  };

  validRange = (searchObject) => {
    let key = this.state.user;
    let errorMessage = "";
    switch (key) {
      case REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO:
        if (
          searchObject.toAmountDisbursed > 0 &&
          searchObject.fromAmountDisbursed >
          searchObject.toAmountDisbursed
        ) {
          errorMessage = Strings("FromAmountMustBeLess");
        }

        break;
      case REPORTS_TAB_KEY_CONST.SALES:
        break;
      case REPORTS_TAB_KEY_CONST.SPECIAL_SALES:
        break;
      case REPORTS_TAB_KEY_CONST.COLLECTIONS:
        break;
      case REPORTS_TAB_KEY_CONST.WEB_COLLECTIONS:
        break;

      default:
        break;
    }
    this.setState({ errorMessage: errorMessage });
    return errorMessage === "";
  };

  toggleCollpasibleView = () => {
    this.setState({
      isFilterDivExpanded: !this.state.isFilterDivExpanded,
    });
  };

  downloadFile = (filetype) => {
    let searchObject = this.state[this.state.user];
    if (this.validRange(searchObject)) {
      this.deleteEmptyKeys(searchObject);

      let searchFilterObject = {
        search: {
          ...searchObject,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          file: filetype,
          exactMatch: this.props.isTwoStepAction,
          visitDays: searchObject.visitDays,
        },
      };
      this.props.downloadReports(
        this.state.downLoadUrl,
        searchFilterObject,
        moment(this.state.toDate).diff(this.state.fromDate, "days") <=
        this.props.maxDays
      );
    }
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "ts":
        return moment(row[key]).format(INVOICE_DATE_FORMAT);

      case "rt":
        return parseFloat(row[key]).toFixed(2);
      default:
        return row[key] || row[key] === 0
          ? Strings(row[key])
          : Strings("NA");
    }
  };

  handleExpandClick = (id) => {
    this.props.setRowClickAction(null, null);
    let expandIndexId = this.state.expanded;
    const index = expandIndexId.indexOf(id);
    expandIndexId.includes(id)
      ? expandIndexId.splice(index, 1)
      : expandIndexId.push(id);
    this.setState({
      expanded: expandIndexId,
    });
  };

  renderCustomDataGrid = (
    columns,
    rows,
    isSummary,
    isExpandable,
    callBack
  ) => {
    return (
      <div
        className={
          this.props.user === REPORTS_TAB_KEY_CONST.SALES || isSummary
            ? "mx-5 w-75"
            : "mx-5 "
        }
      >
        <CustomTableCard
          rows={rows}
          columns={columns}
          secondRowColumns={LOAN_PORTFOLIO_SECOND_ROW}
          isSummary={isSummary}
          isExpandable={isExpandable}
          expanded={this.state.expanded}
          setRowClickAction={this.props.setRowClickAction}
          handleExpandClick={this.handleExpandClick}
          callBack={callBack}
        />
      </div>
    );
  };

  render() {
    //NOSONAR
    let zones =
      this.state?.zones.map((singleZone) => {
        return singleZone.name === Strings("Zone")
          ? {
            label: GET_DEFAULT_ZONE_SELECTION().name,
            name: GET_DEFAULT_ZONE_SELECTION().name,
          }
          : {
            label: singleZone.name,
            name: singleZone.name,
          };
      });
    let mZones =
      this.state?.mZones.map((singleZone) => {
        return singleZone.name === Strings("mzone")
          ? {
            label: GET_DEFAULT_MESSENGER_ZONE_SELECTION().name,
            name: GET_DEFAULT_MESSENGER_ZONE_SELECTION().name,
          }
          : {
            label: singleZone.name,
            name: singleZone.name,
          };
      });
    const salesModeratorUsers = Utility.getSalesModeratorUsers(
      this.props.users
    );

    let FirstLevelReportsSearchFilters = {
      LOAN_PORTFOLIO: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.fromDate,
          searchPlaceHolder: Strings("Date"),
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartName"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.minimartName,
          key: "minimartName",
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.matricesId,
          key: "matricesId",
          fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("CIID"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.ciCode,
          key: "ciCode",
          fieldname: SEARCH_FILTER_FIELDS.CI_ID
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("Branch"),
          handleOnChange: this.handleBranchChange,
          Value: this.state.LOAN_PORTFOLIO.branch,
          key: "branch",
          filterDropdownList: this.state.branches,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("Zone"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.zone,
          key: "zone",
          filterDropdownList: zones,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("mzone"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.messengerZone,
          key: "messengerZone",
          filterDropdownList: mZones,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("loanStatus"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.status,
          key: "status",
          filterDropdownList: LOAN_STATUS,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("type"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.type,
          key: "type",
          filterDropdownList: LOAN_TYPE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          searchPlaceHolder: Strings("LoanId"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.loanId,
          key: "loanId",
          fieldname: SEARCH_FILTER_FIELDS.LOAN_ID,
          
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("Provison"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.provision,
          key: "provision",
          filterDropdownList: PROVISION,
        },
        {
          type: SEARCH_FILTER_TYPE.DOUBLE_INPUT,
          searchPlaceHolder: Strings("DisbursedAmount"),
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          handleOnChange: this.handleSearchChange,
          Value1: this.state.LOAN_PORTFOLIO.fromAmountDisbursed,
          key: "fromAmountDisbursed",
          searchPlaceHolder1: Strings("FromAmount"),
          searchPlaceHolder2: Strings("toAmount"),
          Value2: this.state.LOAN_PORTFOLIO.toAmountDisbursed,
          key1: "toAmountDisbursed",
          fieldname: SEARCH_FILTER_FIELDS.DOUBLE_INPUT

        },
        {
          // Sales moderator
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("supervisor"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.userId,
          key: "userId",
          filterDropdownList: salesModeratorUsers,
        },
      ],
      SALES: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.fromDate,
          searchPlaceHolder: Strings("Date"),
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartName"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.SALES.minimartName,
          key: "minimartName",
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("selectLoanStatus"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.SALES.status,
          key: "status",
          filterDropdownList: LOAN_STATUS,
        },
      ],
      SPECIAL_SALES: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.fromDate,
          searchPlaceHolder: Strings("Date"),
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartName"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.SPECIAL_SALES.minimartName,
          key: "minimartName",
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME
        },
      ],
      COLLECTIONS: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.fromDate,
          searchPlaceHolder: Strings("Date"),
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartName"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.COLLECTIONS.minimartName,
          key: "minimartName",
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("route"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.COLLECTIONS.visitDays,
          key: "visitDays",
          filterDropdownList: ROUTE_DAYS,
        },
      ],
      WEB_COLLECTIONS: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.fromDate,
          searchPlaceHolder: Strings("Date"),
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartName"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.WEB_COLLECTIONS.minimartName,
          key: "minimartName",
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("route"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.WEB_COLLECTIONS.visitDay,
          key: "visitDay",
          filterDropdownList: ROUTE_DAYS,
        },
      ],
      WALLET: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.fromDate,
          searchPlaceHolder: Strings("Date"),
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.WALLET.matricesId,
          key: "matricesId",
          fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("applicationId"),
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          handleOnChange: this.handleSearchChange,
          Value: this.state.WALLET.applicationId,
          key: "applicationId",
          fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          searchPlaceHolder: Strings("LoanId"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.WALLET.loanId,
          key: "loanId",
          fieldname: SEARCH_FILTER_FIELDS.LOAN_ID,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("daysInArrears"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.WALLET.dayInArrears,
          key: "dayInArrears",
          fieldname: SEARCH_FILTER_FIELDS.DAYS_IN_ARREARS,
        },
      ],
    };

    let leftButtonSelection =
      window.location.href.endsWith(
        ROUTES.REPORTS_FOR_SINGLE_LOAN_PORTFOLIO
      ) ||
        window.location.href.endsWith(ROUTES.REPORTS_FOR_SINGLE_WALLET)
        ? "unSelectedButtonLeft"
        : "selectedButtonLeft";

    let rightButtonSelection =
      window.location.href.endsWith(
        ROUTES.REPORTS_FOR_SINGLE_LOAN_PORTFOLIO
      ) ||
        window.location.href.endsWith(ROUTES.REPORTS_FOR_SINGLE_WALLET)
        ? "selectedButtonRight"
        : "unselectedButtonRight";

    return (
      <div>
        {this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO ||
          this.props.user === REPORTS_TAB_KEY_CONST.WALLET ? (
          <Row className='pt-2 alignItemCenter'>
            <Col md={4}>
              <Button
                className={leftButtonSelection}
                onClick={() => {
                  this.props.goToAllLoanReports(
                    this.props.user ===
                    REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
                  );
                }}
              >
                {this.props.user === //NOSONAR
                  REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
                  ? Strings("loanCount")
                  : Strings("walletReports")}
              </Button>
              <Button
                className={rightButtonSelection}
                onClick={() =>
                  this.props.goToSingleLoanReports(
                    this.props.user ===
                    REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
                  )
                }
              >
                {this.props.user === //NOSONAR
                  REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
                  ? Strings("loanList")
                  : Strings("disbursementReport")}
              </Button>
            </Col>
            <Col
              md={{ span: 2, offset: 6 }}
              className='pull-right alignDownloadButtons'
            >
              {" "}
              <div>
                <Button
                  className='downloadButtons mr-3'
                  onClick={() => {
                    this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
                  }}
                >
                  <img src={PDFdownload} alt='' />
                </Button>
                <Button
                  className='downloadButtons'
                  onClick={() => {
                    this.downloadFile(DOWNLOAD_FILE_TYPE.CSV);
                  }}
                >
                  <img src={CSVdownload} alt='' />
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          this.renderHeader()
        )}
        {this.state.errorMessage !== "" && (
          <Row className='errors pl-3'>
            {this.state.errorMessage !== "" &&
              Strings(this.state.errorMessage)}
          </Row>
        )}
        <div>
          <SearchFilter
            filters={FirstLevelReportsSearchFilters[this.props.user]}
            handleOnClickSearchFilter={this.handleClickSearchFilter}
            handleOnClickResetFilter={this.handleOnClickResetFilter}
            paddingLeft={true}
            addButton={true}
            buttonText={Strings("Descargar")}
            handleAddUser={this.downloadReports}
            buttonWidth={
              FirstLevelReportsSearchFilters[
              `${this.props.user}_buttonWidth`
              ]
            }
          />
          {this.props.errorMessage !== "" && (
            <Row className='errors pl-3'>
              {this.props.errorMessage !== ""
                ? Strings(this.props.errorMessage)
                : ""}
            </Row>
          )}
          {this.props.user !== REPORTS_TAB_KEY_CONST.SALES &&
            this.props.user !== REPORTS_TAB_KEY_CONST.SPECIAL_SALES &&
            this.props.sumRows.length !== 0 && (
              <div>
                <Row className='pl-3 heading'>
                  {Strings("Summary")}
                </Row>
                <Collapse in={this.state.isFilterDivExpanded}>
                  <div className='branchListTable noPadding d-flex justify-content-center'>
                    {this.state.isFilterDivExpanded &&
                      this.renderCustomDataGrid(
                        this.__Sumcolumns,
                        this.props.sumRows,
                        true,
                        false
                      )}
                  </div>
                </Collapse>
                <Row className='collapseContainer mt-2'>
                  <img
                    src={
                      this.state.isFilterDivExpanded
                        ? pageDividerOpened
                        : pageDividerClosed
                    }
                    alt='img'
                    className='collapseIcon pull-right'
                    onClick={this.toggleCollpasibleView}
                  />
                  <hr className='newCollapseHr' />
                </Row>
              </div>
            )}
          {this.props.rowsCount !== 0 ? (
            <>
              {" "}
              <Row className='pl-3 heading'>
                {Strings("ReportDetails")}
              </Row>
              <div
                className={
                  this.props.user === REPORTS_TAB_KEY_CONST.SALES //NOSONAR
                    ? "branchListTable noPadding  d-flex justify-content-center"
                    : "branchListTable noPadding"
                }
              >
                {this.renderCustomDataGrid(
                  this._columns,
                  this.props.rows,
                  false,
                  this.props.user ===
                  REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO,
                  this.props.onRowClick
                )}
              </div>
            </>
          ) : (
            <div className='noRecordsFound'>
              {Strings("NoRecordsFound")}
            </div>
          )}
          <Row>
            <Col md={1} className='alignCenter pl-2 pt-3 pb-1'>
              <Button
                className='buttonBorder'
                onClick={this.props.goBack}
              >
                {Strings("Back").toUpperCase()}
              </Button>
            </Col>
            {this.props.rowsCount !== 0 && (
              <Col md={11} className='noPadding alignPagination'>
                <Pagination
                  activePage={this.state.activePage}
                  recordsPerPage={this.state.recordsPerPage}
                  numberOfPages={this.props.numberOfPages}
                  onPageChange={this.onPageChange}
                  dropup={true}
                />
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  }
}

export default SecondLevelReports;
