import React from "react";
import moment from "moment";
import { Col, Row, Button } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

// Component
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

//Constants
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  INVOICE_DATE_FORMAT,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  INVOICE_DETAILS_KEYS,
  INVOICE_STATUS_LIST,
  DEFAULT_LIST,
  INVOICE_STATUS_LEGEND,
} from "./Constants";
//Strings
import { Strings } from "../../resources/i18n/i18n";
//Utility
import Utility from "../../utils/Utility";

class InvoiceApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceFilter: {
        fromDate: DEFAULT_DURATION.fromDate,
        toDate: DEFAULT_DURATION.toDate,
        code: "",
        invoiceNumber: "",
        status: "PENDING",
      },
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      errorMessage: props.errorMessage,
      showModal: false,
      invoice: null,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(true);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  closeModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  approvedClick = (status) => {
    this.props.handleApprove(
      {
        id: this.state.invoice.id,
        st: status,
      },
      () => {
        this.closeModal();
        this.handleOnClickSearchFilter();
      }
    );
  };
  renderInvoiceModal = () => {
    return (
      <Modal
        // Change visibility of modal based on props.
        open={this.state.showModal}
        onClose={this.closeModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        classNames={{ modal: "loanDetailModal" }}
        center
        focusTrapped={false}
        showCloseIcon={false}
      >
        <Row className={"loanDetailModal"}>
          <Col md={12} className='loanDetailModalHeader pl-3'>
            {Strings("invoiceDetails")}
          </Col>
          {/* Show only specified props of modal */}
          <Col md={12} className='modalContent pt-3'>
            {this.state.errorMessage && (
              <Row className='errors p-0'>
                {Strings(this.state.errorMessage)}
              </Row>
            )}
            {this.state.invoice.sellerUserName === null && (
              <Row className="warningText">
                {Strings("NoSellerAssignedWarning").replace('*', this.state.invoice.minimartCode)}
              </Row>
            )}
            <Row>
              {INVOICE_DETAILS_KEYS.map((invoice) => (
                <Col
                  md={{ span: 8, offset: 2 }}
                  className='d-flex'
                  key={invoice.key}
                >
                  <span className='titleText'>
                    {Strings(invoice.label)} &nbsp;:&nbsp;
                  </span>

                  <span className={`detailText ${invoice.className}`}>
                    {this.state.invoice[invoice.key]
                      ? invoice.key === "date" || //NOSONAR
                        invoice.key === "expiryDate"
                        ? moment(
                          this.state.invoice[invoice.key]
                        ).format(INVOICE_DATE_FORMAT)
                        : Strings(this.state.invoice[invoice.key])
                      : Strings("NA")}
                  </span>
                </Col>
              ))}
            </Row>
            <Row className='py-2'>
              <Col md={{ span: 3, offset: 2 }}>
                <Button
                  className='capitalText buttonBackground'
                  onClick={() => {
                    this.approvedClick("APPROVED");
                  }}
                  disabled={this.state.invoice.status !== "PENDING"}
                >
                  {Strings("approve")}
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  className='capitalText redButtonBorder'
                  onClick={() => {
                    this.approvedClick("REJECT");
                  }}
                  disabled={this.state.invoice.status !== "PENDING"}
                >
                  {Strings("reject")}
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  className='buttonBorder'
                  onClick={this.closeModal}
                >
                  {Strings("Cancel")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    );
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    let dateKey = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.invoiceFilter.toDate)) {
          DateValue = this.state.invoiceFilter.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        dateKey = "fromDate";
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.invoiceFilter.fromDate)) {
          DateValue =
            moment(dateMoment).diff(
              this.state.invoiceFilter.fromDate,
              "days"
            ) === 0
              ? this.state.invoiceFilter.fromDate
              : this.state.invoiceFilter.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        dateKey = "toDate";
        break;
      default:
    }
    this.setState({
      invoiceFilter: {
        ...this.state.invoiceFilter,
        [dateKey]: DateValue,
      },
    });
  };

  handleInputChange = (event) => {
    let value = DEFAULT_LIST.includes(event.target.value)
      ? ""
      : event.target.value;
    this.setState({
      errorMessage: "",
      invoiceFilter: {
        ...this.state.invoiceFilter,
        [event.target.name]: value,
      },
    });
  };

  handleOnClickSearchFilter = (isDefaultOffset = false) => {
    isDefaultOffset &&
      this.setState({
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      });
    this.props.handleOnClickSearchFilter({
      search: {
        fd: Utility.convertDateIntoMiliSeconds(
          this.state.invoiceFilter.fromDate
        ),
        td: Utility.convertDateIntoMiliSeconds(
          this.state.invoiceFilter.toDate
        ),
        mc: this.state.invoiceFilter.code || undefined,
        ino: this.state.invoiceFilter.invoiceNumber || undefined,
        st: this.state.invoiceFilter.status || undefined,
      },
      oft: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
        : this.state.activePage - 1,
      lmt: this.state.recordsPerPage,
    });
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        invoiceFilter: {
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: DEFAULT_DURATION.toDate,
          code: "",
          invoiceNumber: "",
          status: "PENDING",
        },
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        errorMessage: "",
      },
      this.handleOnClickSearchFilter
    );
  };

  renderSearchFilter = () => {
    let invoiceFilter = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        fromDate: this.state.invoiceFilter.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.invoiceFilter.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("InvoiceNumber"),
        handleOnChange: this.handleInputChange,
        Value: this.state.invoiceFilter.invoiceNumber,
        fieldname: SEARCH_FILTER_FIELDS.INVOICE_NUMBER,
        name: "invoiceNumber",
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("code"),
        handleOnChange: this.handleInputChange,
        Value: this.state.invoiceFilter.code,
        fieldname: SEARCH_FILTER_FIELDS.CODE,
        name: "code",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("status"),
        handleOnChange: this.handleInputChange,
        Value: this.state.invoiceFilter.status,
        filterDropdownList: INVOICE_STATUS_LIST,
        name: "status",
      },
    ];
    return (
      <SearchFilter
        filters={invoiceFilter}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
      />
    );
  };

  renderLegends = () => {
    return (
      <Row className='d-flex justify-content-between pt-3'>
        <CustomerTypeLegend
          arrayOfTypes={INVOICE_STATUS_LEGEND}
          paddingLeft={4}
        />
      </Row>
    );
  };
  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      this.handleOnClickSearchFilter
    );
  };

  onCardClick = (invoice) => {
    this.setState({
      showModal: !this.state.showModal,
      invoice: invoice,
    });
  };

  renderInvoiceCard = () => {
    return this.props.invoiceList.length > 0 ? (
      <Row className='cardAlign'>
        <Col md={12} className='w-100 pr-0'>
          <Row className='w-100'>
            {this.props.invoiceList.map((card) => {
              return (
                <CustomCard
                  card={card}
                  cardType='invoices'
                  key={card.id}
                  handleCardClick={this.onCardClick}
                />
              );
            })}
          </Row>
        </Col>
        <Col md={12} className='pt-3'>
          <Pagination
            activePage={this.state.activePage}
            recordsPerPage={this.state.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.onPageChange}
            dropup={true}
          />
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoRecordsFound")}
      </div>
    );
  };

  render() {
    return (
      <>
        <Row className='heading'>
          {Strings("invoiceApprovalPageTitle")}
        </Row>
        <div className='loanList container-fluid ListContainer'>
          <div>
            {this.state.showModal && this.renderInvoiceModal()}
            {this.renderSearchFilter()}
            {this.renderLegends()}
            <Row>
              <Col md={12} className=' errorText'>
                {this.state.errorMessage}
              </Col>
            </Row>
            {this.renderInvoiceCard()}
          </div>
        </div>
      </>
    );
  }
}

export default InvoiceApproval;
