import React from "react";
import moment from "moment";
import { Col, Row, Button, Collapse } from "react-bootstrap";

// Component
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import Utility from "../../utils/Utility";
import EditableModal from "./EditableModal";
import LoanAmountModal from "./LoanAmountModal";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";
import CustomTableCard from "../CustomUIComponents/CustomTableCard/CustomTableCard.js";

// String
import { Strings } from "../../resources/i18n/i18n";

// Constants
import {
  TABLE_COLUMNS,
  ASSOCIATION,
  ASSOCIATION_FACTORIAL,
  LOAN_DETAILS_REPORT_DOWNLOAD,
  TABLE_COLUMNS_CONST,
  SUMMARY_TABLE_COLUMNS,
  TABLE_SECOND_COLUMNS,
  PROMOTION_DETAILS_KEYS,
  LOAN_DETAILS_INFO_ACCESS,
  MINIMART_DETAILS_INFO_ACCESS,
  FREQUENCIES,
  EXT_FACT_SUPPLIER_DETAILS,
  FAST_CASH_BANK_DETAILS,
} from "./Constants";
import {
  LOAN_DETAILS_KEYS,
  MINIMART_DETAILS_KEYS,
  LOAN_STATE_KEYS,
  PROVISION_KEYS,
  INVOICE_DATE_FORMAT,
  USERS_WITH_ACCESS_ON_LOAN_APPLICATION_ACTIONS,
  USERS_WITH_ACCESS_ON_LOAN_APPLICATION_REQUEST,
  USERS_WITH_ACCESS_NEW_PAYMENTPLAN,
  USERS_WITH_ACCESS_NEW_PAYMENTPLAN_REQUEST,
  SPECIAL,
  SPECIAL_MESSENGER_PRESENTATION,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  USER_ROLES,
  USER_ROLES_TO_ENABLE_CLOSE_LOAN,
  LOAN_APPLICATION_STATUS,
  LOAN_TYPES,
  LOAN_STATE_KEYS_CONST,
  LOAN_TYPE_KEYS,
  NOTES_TYPE,
  MODAL_TYPE,
  SEARCH_FILTER_PAGES,
  LOAN_STATUS_CATEGORIES,
  DEFAULT_VALUES,
  LOAN_STATUS_AFTER_DISBURSEMENT,
  ADMINISTRATIVE_EXPENSE_KEYS,
  DOWNPAYMENT_COLLECTION_KEYS
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  LOAN_TYPE_LABEL,
} from "../AnalystApproval/Constants";

// Assets
import pageDividerOpened from "../../resources/images/ic_page_divider_arrow.svg";
import pageDividerClosed from "../../resources/images/ic_page_divider_arrow_close.svg";
import loanImage from "../../resources/images/ic_loan_portfolio_details.svg";
import PDFdownload from "../../resources/images/ic_download_pdf.svg";
import editNote from "../../resources/images/ic_edit.svg";
import whatsapp_icon from "../../resources/images/whatsapp_icon.svg";

// Styles
import "./LoanDetails.scss";
import InformativeModal from "../CustomUIComponents/InformativeModal/InformativeModal.js";

class LoanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      loanDetails: [],
      showLoadingModal: this.props.showLoadingModal,
      isFilterDivExpanded: true,
      showModal: false,
      showLoanAmountModal: false,
      loanAmountDivisionData: [],
      noteType: props.noteType,
      notesModal: props.notesModal,
      phone: "",
      mobileNo: "",
      minimartName: "",
      ownerName: "",
      businessType: "",
      city: "",
      sector: "",
      street: "",
      reference: "",
      number: "",
      loanAmountTotalSum: 0,
      expanded: [],
      isWhatsAppNo: false,
      grade: "",
      editButtonKey: 0,
      dayOfVisit: [],
      showConciliationdetailsModal: false,
      showRestructureDetailsModal:false,
      restructureLoanDetail:{},
      modalHeader:""
    };
  }

  componentDidMount() {
    this.setState({
      loanDetails: this.props.loanDetails,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let derievedState = { ...prevState };
    if (
      nextProps?.notesObject?.noteFromCollectionsOff !==
      prevState?.notesObject?.noteFromCollectionsOff
    ) {
      derievedState = {
        ...derievedState,

        noteFromCollectionsOff:
          nextProps?.notesObject?.noteFromCollectionsOff,
      };
    }
    if (
      nextProps?.notesObject?.author !==
      prevState?.notesObject?.author
    ) {
      derievedState = {
        ...derievedState,

        author: nextProps?.notesObject?.author,
      };
    }
    if (
      nextProps?.notesObject?.createdAt !==
      prevState?.notesObject?.createdAt
    ) {
      derievedState = {
        ...derievedState,
        createdAt: nextProps?.notesObject?.createdAt,
      };
    }
    return derievedState;
  }

  toggleCollpasibleView = () => {
    this.setState({
      isFilterDivExpanded: !this.state.isFilterDivExpanded,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        recordsPerPage: pageLength,
        activePage: page,
      },
      () => {
        this.props.onPageChange(
          this.state.activePage,
          this.state.recordsPerPage
        );
      }
    );
  };

  showEditableFields = (key) => {
    //NOSONAR
    if (key === 3) {
      this.props.getPossibleCollectionCycles({
        fr: this.props.loanDetails.info.appfq,
      }, () => {
        this.setState((prevState) => ({
          editButtonKey: key,
          showModal: !prevState.showModal,
          collectionDays: this.props.loanDetails.info.appCd,
          frequency: this.props.loanDetails.info.appfq
        }));
      })

    }
    else if (key === 1) {
      this.setState((prevState) => ({
        showModal: !prevState.showModal,
        grade: this.props.loanDetails.state.ct,
        editButtonKey: key,
      }));
    } else {
      let minimartData = this.props.loanDetails.minimart;
      this.setState((prevState) => ({
        showModal: !prevState.showModal,
        phone: minimartData.tp ? minimartData.tp : "",
        mobileNo: minimartData.otp ? minimartData.otp : "",
        minimartName: minimartData.mn ? minimartData.mn : "",
        ownerName: minimartData.on ? minimartData.on : "",
        businessType: minimartData.bty ? minimartData.bty : "",
        city: minimartData.ct ? minimartData.ct : "",
        sector: minimartData.scr ? minimartData.scr : "",
        street: minimartData.srt ? minimartData.srt : "",
        number: minimartData.no ? minimartData.no : "",
        reference: minimartData.ref ? minimartData.ref : "",
        isWhatsAppNo: minimartData.wnr,
        errorMessage: "",
        editButtonKey: key,
      }));
    }
  };

  SubmitMinimartDetails = () => {
    if (this.state.editButtonKey === 3) {
      this.props.changeCollectionDays(
        {
          lid: this.props.loanId,
          cd: this.state.collectionDays,
        },
        () => {
          this.setState({ showModal: false });
        }
      );
    }
    else if (this.state.editButtonKey === 1) {
      this.props.loanCategoriesDetails(
        {
          lid: this.props.loanId,
          st: this.state.grade,
        },
        () => {
          this.setState({ showModal: false });
        }
      );
    } else if (this.checkValid() && this.state.editButtonKey === 2) {
      this.props.sendUpdatedMinimartDetails(
        {
          mid: this.props.loanDetails.minimart.mid,
          applicationId: this.props.applicationId,
          tp: this.state.phone,
          otp: this.state.mobileNo,
          mn: this.state.minimartName,
          bty: this.state.businessType,
          on: this.state.ownerName,
          wnr: this.state.isWhatsAppNo,
          ct: this.state.city,
          scr: this.state.sector,
          srt: this.state.street,
          no: this.state.number,
          ref: this.state.reference,
        },
        () => {
          this.setState({ showModal: false });
        }
      );
    } else {
      if (
        this.state.phone === "" ||
        this.state.phone?.length === 0 ||
        this.state.mobileNo === "" ||
        this.state.mobileNo?.length === 0 ||
        this.state.minimartName === "" ||
        this.state.businessType === "" ||
        this.state.ownerName === "" ||
        this.state.city === "" ||
        this.state.sector === "" ||
        this.state.street === "" ||
        this.state.reference === ""
      ) {
        this.setState({
          errorMessage: Strings("pleaseEnterRequiredFields"),
        });
      }
      if (this.state.phone && this.state.phone.length !== 10) {
        this.setState({
          errorMessage: Strings("telephoneNumberValidation"),
        });
      }
      if (this.state.mobileNo && this.state.mobileNo.length !== 10) {
        this.setState({
          errorMessage: Strings("mobileNumberValidation"),
        });
      }
    }
  };

  handleInputChange = (event) => {
    let value;
    if (
      event.target.name === "reference" ||
      event.target.name === "sector" ||
      event.target.name === "city" ||
      event.target.name === "street"
    ) {
      value = event.target.value.trimStart();
    } else if (DEFAULT_VALUES.includes(event.target.value)) {
      value = "";
    } else if (event.target.name === "dayOfVisit") {
      value = event.target.value.split(",").map(Number);
    } else {
      value = event.target.value;
    }
    this.setState({
      [event.target.name]:
        event.target.name === "minimartName"
          ? value.toUpperCase()
          : value,
      errorMessage: "",
    });
  };

  checkValid = () => {
    return (
      this.state.phone.length === 10 &&
      this.state.mobileNo &&
      this.state.mobileNo.length === 10 &&
      this.state.minimartName !== "" &&
      this.state.businessType !== "" &&
      this.state.ownerName !== "" &&
      this.state.city !== null &&
      this.state.sector !== null &&
      this.state.street !== null &&
      this.state.number !== null &&
      this.state.reference !== null &&
      this.state.city !== "" &&
      this.state.sector !== "" &&
      this.state.street !== "" &&
      this.state.number !== "" &&
      this.state.reference !== ""
    );
  };

  showAmountDivisionPopUp = (col, row) => {
    if (
      col === TABLE_COLUMNS_CONST.AMOUNT_PAID ||
      (this.props.loanDetails.info.inl &&
        col === TABLE_COLUMNS_CONST.AMOUNT_TO_PAY)
    ) {
      this.props.getLoanAmountPaidDivision(
        {
          lid: this.props.loanId,
          ix: row.index,
          ic: col === TABLE_COLUMNS_CONST.AMOUNT_PAID,
          inl: this.props.loanDetails.info.inl,
          dd: Utility.convertDateIntoMiliSeconds(row.dueDate),
        },
        (responseData) => {
          this.setState({
            showLoanAmountModalTitle:
              col === TABLE_COLUMNS_CONST.AMOUNT_TO_PAY
                ? "amountToPay"
                : "amountPaid",
            showLoanAmountModal: true,
            loanAmountDivisionData: responseData.ad,
            loanAmountTotalSum: responseData.ts,
          });
        }
      );
    }
  };

  downloadFile = () => {
    const requestData = {
      loanId: this.props.loanDetails?.info["id"],
      applicationId: this.props.applicationId,
      inl: this.props.loanDetails?.info.inl,
    };
    this.props.downloadReports(
      LOAN_DETAILS_REPORT_DOWNLOAD.downloadUrl,
      requestData
    );
  };

  createLoanInfoLabel = (loan) => {// NOSONAR
    let value = this.props.loanDetails.info[loan.key]
      ? this.props.loanDetails.info[loan.key]
      : Strings("NA");
    if (loan.label === LOAN_TYPE_LABEL.DISBURSE_DATE) {
      if (
        this.props.loanDetails.info[loan.key] &&
        this.props.loanDetails.info[loan.key] !== null
      ) {
        value = moment(this.props.loanDetails.info[loan.key]).format(
          INVOICE_DATE_FORMAT
        );
      } else {
        value = Strings("NotDisbursed");
      }
    } else if (loan.label === LOAN_TYPE_LABEL.DAYS_IN_ARREARS) {
      value = this.props.loanDetails.info[loan.key];
    } else if (
      loan.label === LOAN_TYPE_LABEL.STATUS ||
      loan.label === LOAN_TYPE_LABEL.TYPE
    ) {
      value = Strings(this.props.loanDetails.info[loan.key]);
    } else if (
      loan.key === LOAN_TYPE_KEYS.AMT ||
      loan.key === LOAN_TYPE_KEYS.EMI ||
      loan.key === LOAN_TYPE_KEYS.PC ||
      loan.key === LOAN_TYPE_KEYS.APP_AMT
    ) {
      value = Utility.getCurrencyRepresentationOfAmount(
        this.props.loanDetails.info[loan.key]
      );
    } else if (loan.key === LOAN_TYPE_KEYS.LCA) {
      return this.props.loanDetails.info[loan.key]
        ? this.props.loanDetails.info[loan.key].id
        : Strings("NA");
    } else if (loan.key === LOAN_TYPE_KEYS.TR) {
      return this.props.loanDetails.info[loan.key]
        ? Strings("transferReceipt")
        : Strings("NA");
    } else if (loan.key === LOAN_TYPE_KEYS.INV) {
      return `${this.props.loanDetails.info[loan.key]} (${this.props.loanDetails.info[LOAN_TYPE_KEYS.IT]
        ? Strings(this.props.loanDetails.info[LOAN_TYPE_KEYS.IT])
        : Strings("default")
        })`;
    } else if (loan.key === LOAN_TYPE_KEYS.requestedCollectionDays
      || loan.key === LOAN_TYPE_KEYS.approvedCollectionDays) {
      return this.props.loanDetails.info[loan.key]
        ? Utility.convertCollectionDaysToSpanish(this.props.loanDetails.info[loan.key].split(","))
        : Strings("NA");
    }
    return value;
  };
  renderAppDataNotFilledNote = () => {
    if (this.props.loanDetails.info.iff === false && this.props.loanDetails.info.st === "DISBURSED") {
      return (
        <Col md={12} className='pl-0'>
          <span className='warningText'>
            {Strings("AppDataIsNotFilledForLoan")}
          </span>
        </Col>
      );
    }
    return null;
  }

  renderCloseLoanNote = () => {
    if (this.props.loanDetails?.lc) {
      return (
        <>
          <Col md={12} className='pl-0'>
            <span className=' detailText'>
              {Strings("CloseLoanUserName")}
              {this.props.loanDetails?.lc?.cb}
              {Strings("CloseLoanDate")}
              {Utility.getReportFormattedDateString(
                this.props.loanDetails?.lc?.cdt
              )}
            </span>
          </Col>
          <Col md={12} className='pl-0'>
            <span className='detailText scrollContent'>
              {Strings("CloseLoanNote")}
              {this.props.loanDetails?.lc?.nt}
            </span>
          </Col>
        </>
      );
    } else if (this.props.disableCloseLoanButton) {
      return (
        <Row className='w-100'>
          {" "}
          <span className=' detailText'>
            {Strings("CloseDetailsNotAvailable")}
          </span>{" "}
        </Row>
      );
    } else {
      return null;
    }
  };

  renderLoanStateDetail = (state) => {
    let value =
      this.props.loanDetails.state[state.key] !== null
        ? this.props.loanDetails.state[state.key]
        : Strings("NA");

    if (state.label === "category" && value) {
      return Strings(value);
    } else if (
      value &&
      (state.key === LOAN_STATE_KEYS_CONST.LF_AMT ||
        state.key === LOAN_STATE_KEYS_CONST.PDB ||
        state.key === LOAN_STATE_KEYS_CONST.BTD ||
        state.key === LOAN_STATE_KEYS_CONST.UPI)
    ) {
      return Utility.getCurrencyRepresentationOfAmount(
        this.props.loanDetails.state[state.key]
      );
    }
    return value;
  };

  renderProvisionDetails = () => {
    return PROVISION_KEYS.map((provision, index) => {
      let value =
        provision.key === LOAN_TYPE_KEYS.AMT
          ? Utility.getCurrencyRepresentationOfAmount(
            this.props.loanDetails.provision[provision.key]
          )
          : this.props.loanDetails.provision[provision.key];

      return (
        <div
          className='col-lg-6 alignVertical contentWidth'
          key={
            this.props.loanDetails.provision[provision.key] + index
          }
        >
          <span className=' titleText'>
            {Strings(provision.label)}&nbsp;:&nbsp;
          </span>

          <span className='detailText'>
            {value ? value : Strings("NA")}
          </span>
        </div>
      );
    });
  };

  renderAdministrativeExpenses = () => {
    return ADMINISTRATIVE_EXPENSE_KEYS.map((administrativeExpense, index) => {
      let value = this.props.loanDetails.info.aepi[administrativeExpense.key];
      let onClickEvent;
      let className = 'detailText';
      if (administrativeExpense.key === LOAN_TYPE_KEYS.amountCharged
        || administrativeExpense.key === LOAN_TYPE_KEYS.amountPaid) {
        value = this.props.loanDetails.info.aepi[administrativeExpense.key]
          ? Utility.getCurrencyRepresentationOfAmount(
            this.props.loanDetails.info.aepi[administrativeExpense.key])
          : Strings("NA")
      } else if (administrativeExpense.key === LOAN_TYPE_KEYS.receiptIds) {
        value = this.props.loanDetails.info.aepi[administrativeExpense.key].length > 0
          ? this.props.loanDetails.info.aepi[administrativeExpense.key]
          : Strings("NA");
        this.props.loanDetails.info.aepi[administrativeExpense.key].forEach((receiptId) => {
          onClickEvent = this.props.loanDetails.info.aepi[administrativeExpense.key].length > 0
            ? () => {
              this.props.viewReceipt(receiptId);
            }
            : () => { }
        })
        className = this.props.loanDetails.info.aepi[administrativeExpense.key].length > 0
          ? "detailText url cursorPointer textColor"
          : "detailText"
      } else if (administrativeExpense.key === LOAN_TYPE_KEYS.transferReceiptIds) {
        value = this.props.loanDetails.info.aepi[administrativeExpense.key].length > 0
          ? Strings("view")
          : Strings("NA");
        this.props.loanDetails.info.aepi[administrativeExpense.key].forEach((_transferReceiptId, index) => {
          const s3rUl = Utility.getDecryptedData(this.props.loanDetails.info.aepi[LOAN_TYPE_KEYS.transferReceiptUrl][index])
          onClickEvent = this.props.loanDetails.info.aepi[administrativeExpense.key].length > 0
            ? () => {
              window.open(s3rUl);
            }
            : () => { }
        })
        className = this.props.loanDetails.info.aepi[administrativeExpense.key].length > 0
          ? "detailText url cursorPointer textColor"
          : "detailText"
      } else if (administrativeExpense.key === LOAN_TYPE_KEYS.paymentModes
        || administrativeExpense.key === LOAN_TYPE_KEYS.paymentSources) {
        value = this.props.loanDetails.info.aepi[administrativeExpense.key].length > 0
          ? Strings(this.props.loanDetails.info.aepi[administrativeExpense.key])
          : Strings("NA");
      }
      return (
        <div
          className='col-lg-6 alignVertical contentWidth'
          key={
            this.props.loanDetails.info.aepi[administrativeExpense.key] + index
          }
        >
          <span className=' titleText'>
            {Strings(administrativeExpense.label)}&nbsp;:&nbsp;
          </span>

          <span className={className} onClick={onClickEvent}>
            {value}
          </span>
        </div>
      );
    });
  };

  renderLoanId = () => {
    return (
      this.props.loanDetails?.rlids !== null &&
      this.props.loanDetails?.rlids?.length > 0 && (
        <Col md={3} className='px-0 pt-2 titleText '>
          {Strings("RescheduledLoanId")}
        </Col>
      )
    );
  };
  renderLoanIdVal = () => {
    if (
      this.props.loanDetails?.rlids &&
      this.props.loanDetails?.rlids?.length > 0
    ) {
      return (
        <Col md={9} className=' pl-0 pt-2 detailText'>
          {this.props.loanDetails?.rlids.map((loanId) => {
            return (
              <span
                className='pr-2 loanID'
                onClick={() => {
                  this.props.getLoanDetailsById(
                    this.props.applicationId,
                    loanId
                  );
                }}
                role='presentation'
                key={"loanID" + loanId + Math.random()}
              >
                {loanId}
              </span>
            );
          })}
        </Col>
      );
    }
  };

  toggleModal = () => {
    this.setState({
      key: 0,
      showModal: false,
    })
  }

  renderEditableModal = () => {
    let editDetails = [];
    if (this.state.editButtonKey === 3) {
      editDetails = [
        {
          title: Strings("collectionDays"),
          value: this.state.collectionDays,
          isMandatoryField: true,
          name: "collectionDays",
          dropDownList: this.props.possibleCollectionDays,
          type: "select",
          isSelection: true,
        },
      ];
    } else if (this.state.editButtonKey === 1) {
      editDetails = [
        {
          title: Strings("Grade"),
          value: this.state.grade,
          isMandatoryField: true,
          name: "grade",
          dropDownList: LOAN_STATUS_CATEGORIES,
          type: "select",
          isSelection: true,
        },
      ];
    } else if (this.state.editButtonKey === 2) {
      editDetails = [
        {
          title: Strings("MiniMarketName"),
          value: this.state.minimartName,
          isMandatoryField: true,
          name: "minimartName",
          type: "text",
          md: 12,
        },
        {
          title: Strings("BusinessType"),
          value: this.state.businessType,
          isMandatoryField: true,
          name: "businessType",
          dropDownList: this.props.businessTypeList,
          type: "select",
          isSelection: true,
        },
        {
          title: Strings("ownerName"),
          value: this.state.ownerName,
          isMandatoryField: true,
          name: "ownerName",
          type: "text",
        },
        {
          title: Strings("Sector"),
          value: this.state.sector,
          isMandatoryField: true,
          name: "sector",
          type: "text",
        },
        {
          title: Strings("Street"),
          value: this.state.street,
          isMandatoryField: true,
          name: "street",
          type: "text",
        },
        {
          title: Strings("number"),
          value: this.state.number,
          isMandatoryField: true,
          name: "number",
          type: "text",
        },
        {
          title: Strings("Reference"),
          value: this.state.reference,
          isMandatoryField: true,
          name: "reference",
          type: "text",
        },
        {
          title: Strings("City"),
          value: this.state.city,
          isMandatoryField: true,
          name: "city",
          type: "text",
        },
        {
          title: Strings("PhoneNumber"),
          value: this.state.phone,
          isMandatoryField: true,
          name: "phone",
          type: "number",
        },
        {
          title: Strings("MobileNumberOfOwner"),
          value: this.state.mobileNo,
          isMandatoryField: true,
          name: "mobileNo",
          type: "number",
        },
      ];
    }
    return (
      <EditableModal
        open={this.state.showModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={
          this.state.editButtonKey === 1
            ? Strings("EditLoanDetails")
            : Strings("EditMinimartDetails")
        }
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.toggleModal,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.SubmitMinimartDetails,
        }}
        onChange={this.handleInputChange}
        editableData={editDetails}
        SubmitMinimartDetails={this.SubmitMinimartDetails}
        errorMessage={this.state.errorMessage}
        isWhatsAppNo={this.state.isWhatsAppNo}
        currentCollectionCycle={
          this.state.editButtonKey !== 1
            ? this.props.currentCollectionCycle
            : ""}
      />
    );
  };
  addEMINote = (notes) => {
    this.props.addEMINote({
      type: this.props.noteType,
      note: notes,
      index: this.props.index,
    });
    this.props.toggleModal();
  };

  editNote = (note) => {
    this.props.updateNote({ note: note, noteId: this.props.noteId });
    this.props.toggleModal();
  };

  deleteNote = () => {
    this.props.deleteNote(this.props.noteId);
    this.props.toggleModal();
  };

  renderNotesModal = () => {
    return (
      this.state.noteFromCollectionsOff !== undefined &&
      this.state.noteFromCollectionsOff !== null && (
        <NotesModal
          open={this.props.notesModal}
          modalHeader={Strings("Notes")}
          note={this.state.noteFromCollectionsOff}
          author={this.state.author}
          createdAt={this.state.createdAt}
          toggleModal={this.props.toggleModal}
          noteType={this.props.noteType}
          addNote={
            this.props.modalType === MODAL_TYPE.ADD_TYPE &&
            this.addEMINote
          }
          editNote={
            this.props.modalType !== MODAL_TYPE.ADD_TYPE &&
            this.editNote
          }
          isEditNote={this.props.modalType === MODAL_TYPE.EDIT}
          isNotesCompulsory={true}
          deleteNote={this.deleteNote}
          disabled={
            this.props.noteType === NOTES_TYPE.COL_OFF_EMI_INPUT &&
            this.props.logInUserInfo.role !==
            USER_ROLES.collectionsOfficer
          }
          maxLength={150}
        />
      )
    );
  };

  renderAssociationMapping = (association) => {
    let associationKey;
    if (
      this.props.loanDetails.association[association.key] === null ||
      this.props.loanDetails.association[association.key] ===
      undefined
    ) {
      associationKey = Strings("NA");
    } else if (
      association.subKey &&
      this.props.loanDetails.association[association.subKey] ===
      SPECIAL
    ) {
      associationKey =
        SHORT_SPECIAL_MESSENGER_PRESENTATION +
        " " +
        this.props.loanDetails.association[association.key];
    } else {
      associationKey =
        this.props.loanDetails.association[association.key];
    }

    return (
      <div
        className='col-lg-6 alignVertical contentWidth'
        key={association.key}
      >
        <span className='titleText'>
          {Strings(association.label)}&nbsp;:&nbsp;
        </span>
        <span
          className='detailText'
          title={
            association.subKey &&
              this.props.loanDetails.association[association.subKey] ===
              SPECIAL
              ? this.props.loanDetails.association[association.key] +
              " " +
              SPECIAL_MESSENGER_PRESENTATION
              : Strings(
                this.props.loanDetails.association[association.key]
              )
          }
        >
          {Strings(associationKey)}
        </span>
      </div>
    );
  };

  getRdtVal = (value) => {
    return value !== null
      ? Utility.getReportFormattedDateString(value)
      : Strings("NotAvailable");
  };
  getConciliationHeaderString = (loanDetails) => {
    if (!loanDetails) return null;

    const headerStrings = [];
    if (loanDetails.cdd && (loanDetails.ocld && loanDetails.ncld)) {
      headerStrings.push(Strings("closedByConciliation"));
    }
    else if (loanDetails.cdd) {
      headerStrings.push(Strings("createdByConciliation"));
    }
    else if (loanDetails.ocld && loanDetails.ncld) {
      headerStrings.push(Strings("closedByConciliation"));
    }
    if (loanDetails.rc) {
      if (loanDetails.rc.crld !== null && loanDetails.rc.cld !== null) {
        headerStrings.push(Strings("closedByRestructuring"));
      }
      else if (loanDetails.rc.crld !== null) {
        headerStrings.push(Strings("closedByRestructuring"));
      } else if (loanDetails.rc.cld !== null) {
        headerStrings.push(Strings("createdByRestructuring"));
      }
    }
    return headerStrings.length > 0
      ? ` - ${headerStrings.join(" || ")}`
      : null;
  };

  renderHeadingRow = () => {
    const isRestructuring = this.props.loanDetails?.rc?.clrd !== null || this.props.loanDetails?.rc?.cld !== null;
    const isConciliationOrRestructuring =
      (this.props.loanDetails &&
        (this.props.loanDetails.cdd ||
          (this.props.loanDetails.ocld && this.props.loanDetails.ncld))) || isRestructuring;

    return (
      <React.Fragment>
        <Col md={isConciliationOrRestructuring ? 8 : 3} className='pl-0'>
          <span className='subHeading bold '>
            {Strings("loanDetails")} &nbsp;-&nbsp;
          </span>
          <span
            className='detailedlHeading arrowPointer'
            title={Strings("loanId")}
          >
            {this.props.loanDetails?.info["id"]}
            {this.getConciliationHeaderString(this.props.loanDetails)}
          </span>
        </Col>
        <Col
          md={{ span: 1, offset: isConciliationOrRestructuring ? 3 : 8 }}
          className='pull-right alignDownloadButtons'
        >
          <div>
            <Button
              className={
                this.props.loanDetails?.info["id"] === null ||
                  this.props.loanDetails?.info["id"] === undefined
                  ? 'buttonBackground mr-3 cursorNotAllowed'
                  : 'buttonBackground mr-3'}
              onClick={this.downloadFile}
              disabled={
                this.props.loanDetails?.info["id"] === null ||
                this.props.loanDetails?.info["id"] === undefined
              }
              title={Strings("loanDetailsPdfDownloadNotAllowed")}
            >
              <img src={PDFdownload} alt='' />
            </Button>
          </div>
        </Col>
      </React.Fragment>
    );
  };

  renderReschedule = () => {
    return (
      <>
        <Row className='pt-3 pb-2 underlineText'>
          {Strings("rescheduledLoanDetails")}&nbsp;&nbsp;
          {this.props.loanDetails?.rc?.rwvf &&
            <span
              onClick={(e) => {
              e.preventDefault();
              this.handleClickOnRestructureDetails(this.props.loanDetails?.rc?.rwvf,0);
              }}
              className='textColor cursorPointer viewConciliationLabel'
              style={{ textDecoration: 'underline' }}
            >
              {Strings("viewMoreDetails")}
            </span>}
        </Row>
        <Row className='alignVertical w-100'>
          {this.props.loanDetails?.rc?.crld && 
          <Col md={6} className = 'px-0'>
            <span className='titleText'>
              {Strings("LoanCreatedByRestructuring").replace("$",this.props.loanDetails?.rc?.crld.rlid)} &nbsp;:&nbsp;
            </span>
            <span
              onClick={(e) => {
              e.preventDefault();
              this.handleClickOnRestructureDetails(this.props.loanDetails?.rc?.crld,1);
              }}
              className='textColor cursorPointer viewConciliationLabel'
              style={{ textDecoration: 'underline' }}
            >
              {Strings("viewMoreDetails")}
            </span>
          </Col>}
          {this.props.loanDetails?.rc?.cld && 
          <Col md={6} className = 'px-0'>
            <span className='titleText'>
                {Strings("LoanClosedByRestructuring").replace("$",this.props.loanDetails?.rc?.cld.rlid)} &nbsp;:&nbsp;
            </span>
            <span
              onClick={(e) => {
              e.preventDefault();
              this.handleClickOnRestructureDetails(this.props.loanDetails?.rc?.cld,2);
              }}
              className='textColor cursorPointer viewConciliationLabel'
              style={{ textDecoration: 'underline' }}
            >
              {Strings("viewMoreDetails")}
            </span>
          </Col>}
        </Row>
      </>
    );
  };
  handleClickOnConciliationDetails = () => {
    this.setState({
      showConciliationdetailsModal: true
    })
  }
  handleClickOnRestructureDetails= (loanDetails,modalHeader) => {
    let restructureModalHeaderString="";
    if(modalHeader===0){
     restructureModalHeaderString=Strings("restructureDetailsFor")
    }else if(modalHeader===1){
      restructureModalHeaderString=Strings("CreatedLoanDetails")
    }
    else{
      restructureModalHeaderString=Strings("ClosedLoanDetails")
    }
    this.setState({
      showRestructureDetailsModal: true,
      restructureLoanDetail:loanDetails,
      modalHeader:restructureModalHeaderString
    })
  }
  renderConciliationDetails = () => {
    return (
      <>
        <Row className='pt-3 underlineText'>
          <span>
            {Strings("conciliationDetails")}
          </span>&nbsp;:&nbsp;
          <span
            onClick={(e) => {
              e.preventDefault();
              this.handleClickOnConciliationDetails();
            }}
            className='textColor cursorPointer viewConciliationLabel'
            style={{ textDecoration: 'underline' }}
          >
            {Strings("viewMoreDetails")}
          </span>
        </Row>
        <Row className='alignVertical w-100'>


          <Col md={6} className='px-0'>
            <span className='titleText'>
              {Strings("Amount") + " ($)"}&nbsp;:&nbsp;
            </span>
            <span className='detailText'>
              {this.props.loanDetails.cdd.amt ? Utility.getCurrencyRepresentationOfAmount(this.props.loanDetails.cdd.amt) : Strings("NA")}
            </span>
          </Col>

          <Col md={6} className='px-0'>
            <span className='titleText '>
              {Strings("Address")}&nbsp;:&nbsp;
            </span>
            <span className='detailText address'>
              {this.props.loanDetails.cdd.add
                ? this.props.loanDetails.cdd.add
                : Strings("NA")}
            </span>
          </Col>
          <Col md={6} className='px-0'>
            <span className='titleText '>
              {Strings("telephone")}&nbsp;:&nbsp;
            </span>
            <span className='detailText'>
              {this.props.loanDetails.cdd.tp
                ? this.props.loanDetails.cdd.tp
                : Strings("NA")}
            </span>
          </Col>
        </Row>
      </>
    );
  };

  renderConciliationDetailsModal = () => {
    return (
      <InformativeModal
        openModal={this.state.showConciliationdetailsModal}
        modalHeader={Strings("conciliatedLoanDetailsFor")}
        closeModal={() =>
          this.setState({
            showConciliationdetailsModal: false,
            showConciliationdetailsHeader: "",
          })
        }
        conciliatedLoanDetails={this.props.loanDetails?.cdd}
      />
    );
  };
  renderRestructureDetails = () => {
    return(
      <InformativeModal
        openModal={this.state.showRestructureDetailsModal}
        modalHeader={this.state.modalHeader}
        closeModal={() =>
          this.setState({
            showRestructureDetailsModal: false,
            showRestructureDetailsModalHeader: "",
         })
        }
        restructureDetails={this.state.restructureLoanDetail}
      />
    )
  }
  renderConciliationHistory = () => {
    return (
      <>
        <Row className='pt-3 underlineText'>
          {Strings("conciliationHistory")}
        </Row>
        <Row className='alignVertical w-100'>
          <Col md={6} className='px-0'>
            <span className='titleText '>
              {Strings("conciliationByApplicationID")}&nbsp;:&nbsp;
            </span>
            <span className='detailText'>
              {this.props.loanDetails.lcb
                ?
                this.props.loanDetails.lcb
                : Strings("NA")}
            </span>
          </Col>
          <Col md={6} className='px-0'>
            <span className='titleText '>
              {Strings("unpaidCapital")}&nbsp;:&nbsp;
            </span>
            <span className='detailText'>
              {this.props.loanDetails.ocld.upc
                ? Utility.getCurrencyRepresentationOfAmount(
                  this.props.loanDetails.ocld.upc
                )
                : Strings("NA")}
            </span>
          </Col>

          <Col md={6} className='px-0'>
            <span className='titleText '>
              {Strings("unPaidInterest")}&nbsp;:&nbsp;
            </span>
            <span className='detailText'>
              {this.props.loanDetails.ocld.upi
                ? Utility.getCurrencyRepresentationOfAmount(
                  this.props.loanDetails.ocld.upi
                )
                : Strings("NA")}
            </span>
          </Col>

          <Col md={6} className='px-0'>
            <span className='titleText '>
              {Strings("unpaidLateFees")}&nbsp;:&nbsp;
            </span>
            <span className='detailText'>
              {this.props.loanDetails.ocld.uplf
                ? Utility.getCurrencyRepresentationOfAmount(
                  this.props.loanDetails.ocld.uplf
                )
                : Strings("NA")}
            </span>
          </Col>

          <Col md={6} className='px-0'>
            <span className='titleText '>
              {Strings("conciliationInterest") + " ($)"}&nbsp;:&nbsp;
            </span>
            <span className='detailText'>
              {this.props.loanDetails.ncld.upi
                ? Utility.getCurrencyRepresentationOfAmount(
                  this.props.loanDetails.ncld.upi
                )
                : Strings("NA")}
            </span>
          </Col>

          <Col md={6} className='px-0'>
            <span className='titleText '>
              {Strings("discountInterestAmount") + " ($)"} &nbsp;:&nbsp;
            </span>
            <span className='detailText'>
              {this.props.loanDetails.cdi
                ? Utility.getCurrencyRepresentationOfAmount(
                  this.props.loanDetails.cdi
                )
                : Strings("NA")}
            </span>
          </Col>
        </Row>
      </>
    );
  };

  onReceiptClick = (recepitId) => {
    this.props.downloadDownPaymentRecepit(
      recepitId,
      this.props.applicationId
    );
  };

  renderLoanDetailsData = (
    loan,
    loanLabel,
    onClickEvent
  ) => {

    let urlClassName = "detailText w-100 text-wrap text-break";
    if (loan.key === LOAN_TYPE_KEYS.LCA ||
      loan.key === LOAN_TYPE_KEYS.TR) {
      if (this.props.loanDetails.info[loan.key]) {
        urlClassName = "detailText url cursorPointer textColor"
      }
    }
    return (
      <div
        className={"col-lg-6 alignVertical contentWidth"}
        key={loan.key}
      >
        <span className='titleText '>
          {loanLabel}
          &nbsp;:&nbsp;
        </span>
        <span
          className={urlClassName}
          onClick={
            this.props.loanDetails.info[loan.key]
              ? onClickEvent
              : () => { }
          }
        >
          {Strings(this.createLoanInfoLabel(loan))}
        </span>
      </div>
    );
  };

  renderLoanKeyMapping = () => {
    return (
      this.props.loanDetails !== null &&
      LOAN_DETAILS_KEYS[this.props.loanType].map((loan) => {
        const loanLabel = this.props.loanDetails.info.hasOwnProperty(
          loan.key
        )
          ? `${Strings(loan.label)}`
          : "";

        let onClickEvent = () => {
          //This is intensional
        };
        if (loan.key === LOAN_TYPE_KEYS.LCA) {
          onClickEvent = this.props.loanDetails.info[loan.key]
            ? () => {
              this.props.dowloadLoanCreditAgreement(
                this.props.loanDetails.info[loan.key].url
              );
            }
            : () => {
              //This is intensional
            };
        } else if (loan.key === LOAN_TYPE_KEYS.TR) {
          const s3rUl = Utility.getDecryptedData(this.props.loanDetails.info[loan.key]);
          onClickEvent = () => window.open(s3rUl)
        }
        return (
          Object.keys(this.props.loanDetails.info).includes(
            loan.key
          ) &&
          this.renderLoanDetailsData(
            loan,
            loanLabel,
            onClickEvent
          )
        );
      })
    );
  };

  renderLoanStateMapping = () => {
    return (
      this.props.loanDetails !== null &&
      LOAN_STATE_KEYS.map((state, index) => {
        return (
          this.props.loanDetails.state && (
            <div
              className='col-lg-6 alignVertical contentWidth'
              key={this.props.loanDetails.state[state.key] + index}
            >
              <span className='titleText '>
                {this.props.loanDetails.state.hasOwnProperty(
                  state.key
                )
                  ? `${Strings(state.label)}`
                  : ""}
                &nbsp;:&nbsp;
              </span>

              <span className='detailText'>
                {this.renderLoanStateDetail(state)}
              </span>
            </div>
          )
        );
      })
    );
  };

  renderMinimartDetailsMapping = () => {
    return (
      this.props.loanDetails !== null &&
      MINIMART_DETAILS_KEYS.map((minimart, index) => {
        return (
          <div
            className='col-lg-6 alignVertical contentWidth'
            key={
              this.props.loanDetails.minimart[minimart.key] + index
            }
          >
            <span className='titleText'>
              {Strings(minimart.label)}&nbsp;:&nbsp;
            </span>

            <span className={`detailText ${minimart.className}`}>
              {this.showMinimartData(minimart.key)}
            </span>
            {minimart.key === "otp" &&
              this.props.loanDetails.minimart.wnr && (
                <img
                  className='pl-2'
                  src={whatsapp_icon}
                  alt='whatsapp_icon'
                />
              )}
          </div>
        );
      })
    );
  };

  showMinimartData = (key) => {
    if (
      this.props.loanDetails.minimart &&
      (this.props.loanDetails.minimart[key] === undefined ||
        this.props.loanDetails.minimart[key] === null)
    ) {
      return Strings("NA");
    } else {
      return Strings(this.props.loanDetails.minimart[key]);
    }
  };

  renderTakeOffLateFees = () => {
    return (
      <Col md={3} className='pl-0'>
        <Button
          className='buttonBackground bold w-100'
          onClick={
            //Request for approval
            this.props.ticketId ||
              (this.props.searchPage ===
                SEARCH_FILTER_PAGES.LOAN_PORTFOLIO &&
                this.props.lateFeeTicketId)
              ? this.props.onClickViewTakeOffRequest
              :
              //old request which is approved or rejected
              () => {
                this.props.viewPreviousRequest(
                  "TAKE_OFF_LATE_FEES"
                );
              }
          }
          disabled={this.props.loanId === null}
        >
          {Strings("ViewTakeOffRequest")}
        </Button>
      </Col>
    );
  };

  renderAssignRepaymentPlan = () => {
    return (
      <Col md={3} className='pl-0'>
        <Button
          className='buttonBackground bold w-100'
          onClick={
            //Request for approval
            this.props.ticketId
              ? this.props.onClickViewAssignRequest
              :
              //old request which is approved or rejected
              () => {
                this.props.viewPreviousRequest(
                  "NEW_REPAYMENT_PLAN"
                );
              }
          }
          disabled={this.props.loanId === null}
        >
          {Strings("viewAssignRepaymentPlan")}
        </Button>
      </Col>
    );
  };

  renderViewLoanApplicationButton = () => {
    return (
      <Col md={3}>
        <Button
          className='buttonBackground bold w-100'
          onClick={this.props.onClickViewLoanApplication}
        >
          {Strings("viewLoanApplication")}
        </Button>
      </Col>
    );
  };

  renderCloseLoanButton = () => {
    return (
      <Col md={2} className='pl-0'>
        <Button
          className='buttonBackground bold w-100'
          onClick={this.props.showConfirmPopUp}
          disabled={
            this.props.disableCloseLoanButton ||
            (this.props.loanDetails &&
              (this.props.loanDetails.state.ct ===
                LOAN_APPLICATION_STATUS.inactive ||
                this.props.loanDetails.state.ct ===
                LOAN_APPLICATION_STATUS.closed))
          }
        >
          {Strings("Close Loan")}
        </Button>
      </Col>
    );
  };

  renderTakeOffLateFeesRequest = () => {
    return (
      <Col md={3} className='pl-0'>
        <Button
          className='buttonBackground bold w-100'
          onClick={this.props.onClickTakeOffLateFees}
          disabled={this.props.loanId === null}
        >
          {Strings("takeOffLateFees")}
        </Button>
      </Col>
    );
  }

  renderAssignNewRepaymentPlanRequest = () => {
    return (
      <Col md={3} className='pl-0'>
        <Button
          className='buttonBackground bold w-100'
          onClick={() => this.props.upcoming()}
          disabled={this.props.loanId === null}
        >
          {Strings("assignNewRepaymentPlan")}
        </Button>
      </Col >
    );
  }

  isRequestScreen = () => {
    return (
      (this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_PORTFOLIO ||
        this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_DETAIL ||
        this.props.searchPage === SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT)
    )
  }

  isRequestScreenOnTheBasisOfStatus = () => {
    return (this.props.loanDetails.state.ct !== "CLOSED" &&
      this.props.loanDetails.state.ct !== "INACTIVE" &&
      this.props.loanDetails.info.st === "DISBURSED")
  }

  renderLoanDetailsButton = () => {
    return (
      <>
        <Row className='d-flex pt-1'>
          {/* View loan application button */}
          {this.renderViewLoanApplicationButton()}

          {/* Close loan button */}
          {USER_ROLES_TO_ENABLE_CLOSE_LOAN.includes(
            this.props.logInUserInfo.role
          ) && this.renderCloseLoanButton()}

          {/* Raise request to take off late fees */}
          {USERS_WITH_ACCESS_ON_LOAN_APPLICATION_ACTIONS.includes(this.props.logInUserInfo.role)
            && this.isRequestScreen()
            && this.isRequestScreenOnTheBasisOfStatus()
            && this.renderTakeOffLateFeesRequest()}

          {/* Raise request to assign new repayment plan */}
          {USERS_WITH_ACCESS_NEW_PAYMENTPLAN.includes(this.props.logInUserInfo.role)
            && this.isRequestScreen()
            && this.isRequestScreenOnTheBasisOfStatus()
            && this.renderAssignNewRepaymentPlanRequest()}

          {/* Approve Take off late fees request and assign new repayment plan request*/}
          {USERS_WITH_ACCESS_ON_LOAN_APPLICATION_REQUEST.includes(this.props.logInUserInfo.role)
            && !this.isRequestScreen()
            && this.isRequestScreenOnTheBasisOfStatus()
            && this.props.activeTab?.value === Strings("takeOffLateFees")
            && this.renderTakeOffLateFees()}

          {/* Approve assign new repayment plan request*/}
          {USERS_WITH_ACCESS_NEW_PAYMENTPLAN_REQUEST.includes(this.props.logInUserInfo.role)
            && !this.isRequestScreen()
            && this.isRequestScreenOnTheBasisOfStatus()
            && this.props.activeTab?.value === Strings("RepaymentPlan")
            && this.renderAssignRepaymentPlan()}

        </Row>
        <hr className='collapseHr' />
      </>
    );
  };

  handleExpandClick = (id) => {
    let expandIndexId = this.state.expanded;
    const index = expandIndexId.indexOf(id);
    expandIndexId.includes(id)
      ? expandIndexId.splice(index, 1)
      : expandIndexId.push(id);
    this.setState({
      expanded: expandIndexId,
    });
  };

  renderCustomDataGrid = (columns, rows, isSummary, isExpandable) => {
    return (
      <div>
        <CustomTableCard
          rows={rows}
          columns={columns}
          secondRowColumns={TABLE_SECOND_COLUMNS}
          isSummary={isSummary}
          isExpandable={isExpandable}
          expanded={this.state.expanded}
          handleExpandClick={this.handleExpandClick}
          logInUserInfo={this.props.logInUserInfo}
          viewReceipt={this.props.viewReceipt}
          showNotesModal={this.props.showNotesModal}
          callBack={this.showAmountDivisionPopUp}
          AMORTIZATION={true}
        />
      </div>
    );
  };

  renderAmortizationTable = () => {
    return (
      <>
        <Row className='mt-4'>
          <Col md={7}>
            <span className='subHeading bold'>
              {Strings("LoanSummary")}
            </span>
          </Col>
          <Col md={12} className='mt-2'>
            {this.props.loanDetails?.info.inl &&
              (this.props.loanDetails?.state.ct ===
                LOAN_STATUS_AFTER_DISBURSEMENT.ACTIVE ||
                this.props.loanDetails.state.ct ===
                LOAN_STATUS_AFTER_DISBURSEMENT.LEGAL ||
                this.props.loanDetails.state.ct ===
                LOAN_STATUS_AFTER_DISBURSEMENT.W_OFF) && (
                <span className='warningText'>
                  {Strings("EMIRoundOffWarning")}
                </span>
              )}
          </Col>
          <Col md={12} className='mt-2'>
            {this.props.loanDetails &&
              (this.props.loanDetails.state.ct ===
                LOAN_STATUS_AFTER_DISBURSEMENT.W_OFF ||
                this.props.loanDetails.state.ct ===
                LOAN_STATUS_AFTER_DISBURSEMENT.DISAPPEARED) && (
                <span className='warningText'>
                  {`${Strings("lateFeesWarning")} ${Strings(
                    this.props.loanDetails.state.ct
                  )}`}
                </span>
              )}
          </Col>
        </Row>
        <Row className='pt-2'>
          <Col md={12}>
            {this.props.amortizationSummary.length > 0 ? (
              this.renderCustomDataGrid(
                SUMMARY_TABLE_COLUMNS,
                this.props.amortizationSummary,
                true,
                false
              )
            ) : (
              <div className='noRecordsFound py-0 h-auto'>
                {Strings("NoRecordsFound")}
              </div>
            )}
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md={7}>
            <span className='subHeading bold'>
              {Strings("AmortizationTable")}
            </span>
          </Col>
        </Row>

        <Row className='pt-2'>
          <Col md={12}>
            {this.props.amortizations.length > 0 ? (
              this.renderCustomDataGrid(
                TABLE_COLUMNS,
                this.props.amortizations,
                false,
                true
              )
            ) : (
              <div className='noRecordsFound py-0 h-auto'>
                {Strings("NoRecordsFound")}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={1} className='alignCenter  py-1'>
            <Button
              className='buttonBorder'
              onClick={this.props.handleBackButtonClick}
            >
              {Strings("Back").toUpperCase()}
            </Button>
          </Col>
          {this.props.amortizations.length > 0 && (
            <Col md={11} className='noPadding alignPagination'>
              <Pagination
                activePage={this.props.activePage}
                recordsPerPage={this.props.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          )}
        </Row>
      </>
    );
  };

  renderCollapseView = () => {
    return (
      <Row className='collapseContainer'>
        <img
          src={
            this.state.isFilterDivExpanded
              ? pageDividerOpened
              : pageDividerClosed
          }
          alt='img'
          className='collapseIcon pull-right'
          onClick={this.toggleCollpasibleView}
        />
        <hr className='collapseHr' />
      </Row>
    );
  };

  renderSupplierDetails = () => {
    return (
      <>
        <Row className='pt-3 underlineText'>
          {Strings("supplierInfo")}
        </Row>
        <Row>
          {this.props.loanDetails !== null &&
            EXT_FACT_SUPPLIER_DETAILS.map(
              (supplierDetails, index) =>
                <div
                  className='col-lg-6 alignVertical contentWidth'
                  key={
                    supplierDetails.key + index
                  }
                >
                  <span className=' titleText'>
                    {Strings(supplierDetails.label)}&nbsp;:&nbsp;
                  </span>

                  <span className='detailText'>
                    {this.props.loanDetails.info[supplierDetails.key]
                      ? this.props.loanDetails.info[supplierDetails.key]
                      : Strings("NA")}
                    {supplierDetails.key === "rnc" && !this.props.loanDetails.info.isVerified
                      ? <span className="RNCInActive"> {"(" + Strings("UnVerified") + ")"}</span>
                      : ""}
                  </span>
                </div>
            )}
        </Row>
      </>
    )
  }

  renderBankDetails = () => {
    return (
      <>
        <Row className='pt-3 underlineText'>
          {Strings("bankDetails")}
        </Row>
        <Row className="w-100">
          {this.props.loanDetails !== null &&
            FAST_CASH_BANK_DETAILS.map(
              (supplierDetails, index) =>
                <div
                  className='col-lg-6 alignVertical contentWidth'
                  key={
                    supplierDetails.key + index
                  }
                >
                  <span className=' titleText'>
                    {Strings(supplierDetails.label)}&nbsp;:&nbsp;
                  </span>

                  <span className='detailText'>
                    {this.props.loanDetails.info[supplierDetails.key]
                      ? this.props.loanDetails.info[supplierDetails.key]
                      : Strings("NA")}
                  </span>
                </div>
            )}
        </Row>
      </>
    )
  }

  renderAssociation = () => {
    const loanType =
      this.props.loanDetails?.info &&
      this.props.loanDetails?.info.ty !== LOAN_TYPES.FACTORING;
    return (
      <>
        <Row className='pt-3 underlineText'>
          {Strings("Association")}
        </Row>
        <Row>
          {loanType
            ? this.props.loanDetails !== null &&
            ASSOCIATION.map((association, index) =>
              this.renderAssociationMapping(association, index)
            )
            : this.props.loanDetails !== null &&
            ASSOCIATION_FACTORIAL.map(
              (association_factorial, index) =>
                this.renderAssociationMapping(
                  association_factorial,
                  index
                )
            )}
        </Row>
      </>
    );
  };

  renderPromotions = () => {
    return this.props.loanDetails.lprd.map((promotion, index) => {
      return (
        <>
          <Row className='pt-3 underlineText w-100'>
            <Col md={12} className='pl-0'>
              {Strings("Promotions") + " #" + (index + 1)}
            </Col>
          </Row>
          <Row className='w-100'>
            {PROMOTION_DETAILS_KEYS.map((dataKey) => {
              return (
                <Col
                  md={6}
                  className='alignVertical pl-0'
                  key={dataKey.key}
                >
                  {dataKey.formmater(dataKey, promotion)}
                </Col>
              );
            })}
          </Row>
        </>
      );
    });
  };

  renderSpecialSalesPerson = () => {
    return (
      <Col md={12} className='px-0'>
        <span className='warningText'>
          {Strings("ssn").replace("$", this.props.loanDetails.ssn)}
        </span>
      </Col>
    );
  };

  renderDownpaymentCollectionSection = () => {
    return this.props.loanType === LOAN_TYPES.MOTORCYCLE_LOAN && (
      <>
        <Row className='pt-3 underlineText'>
          {Strings("downpaymentCollection")}
        </Row>
        <Row className='alignVertical w-100'>
          {DOWNPAYMENT_COLLECTION_KEYS.map((downpayment, index) => {
            let values = this.props.loanDetails.info[downpayment.key] !== null
              ? Strings(this.props.loanDetails.info[downpayment.key])
              : Strings("NA");
            let onClickEvent = () => { };
            let className = "detailText";
            let label = Strings(downpayment.label);
            if (downpayment.key === LOAN_TYPE_KEYS.DAMT) {
              label = Strings("collectedDownpayment") + "/" + Strings("expectedDownpayment");
              values =
                Utility.getCurrencyRepresentationOfAmount(
                  this.props.loanDetails.info[LOAN_TYPE_KEYS.DAMT]
                ) +
                " / " +
                Utility.getCurrencyRepresentationOfAmount(
                  this.props.loanDetails.info[LOAN_TYPE_KEYS.EDAMT]
                );
            }
            if (downpayment.key === LOAN_TYPE_KEYS.DPR) {
              onClickEvent = () =>
                this.onReceiptClick(
                  this.props.loanDetails.info[downpayment.key]
                );
              className = "detailText url cursorPointer textColor";
            } else if (downpayment.key === LOAN_TYPE_KEYS.DPTRI) {
              if (this.props.loanDetails.info[LOAN_TYPE_KEYS.DPTR]) {
                values = Strings("view");
                const s3rUl = Utility.getDecryptedData(this.props.loanDetails.info[LOAN_TYPE_KEYS.DPTR]);
                onClickEvent = () => window.open(s3rUl);
                className = "detailText url cursorPointer textColor";
              } else {
                values = Strings("NA");
                onClickEvent = () => { }
                className = "detailText";
              }
            }
            return values && <div
              className='col-lg-6 alignVertical contentWidth'
              key={this.props.loanDetails.info[downpayment.key] + index}
            >
              <span className='titleText '>
                {this.props.loanDetails.info.hasOwnProperty(
                  downpayment.key
                )
                  ? `${label}`
                  : ""}
                &nbsp;:&nbsp;
              </span>

              <span className={className} onClick={onClickEvent}>
                {values}
              </span>
            </div>
          })}
        </Row>
      </>
    )
  };

  renderCollapse = () => {
    return (
      <Collapse in={this.state.isFilterDivExpanded}>
        <Row>
          <Col md={1}>
            <div className='justify-center display-flex'>
              <img src={loanImage} alt='loan_details_icon' />
            </div>
            <div
              title={Strings("applicationId")}
              className='justify-center display-flex'
            >
              <span className='displayBlock detailText'>
                {this.props.applicationId}
              </span>
            </div>
          </Col>
          {this.props.loanDetails ? (
            <Col
              md={10}
              className=' pl-5 justify-center display-flex'
            >
              <Row>
                {this.props.loanDetails.ssn &&
                  this.renderSpecialSalesPerson()}
                {/* Render close loan Note */}
                {this.renderCloseLoanNote()}
                {/* Render Application data is not filled note */}
                {this.renderAppDataNotFilledNote()}
                {/* Render loan id */}
                {this.renderLoanId()}
                {this.renderLoanIdVal()}
                {/* Render rescheduled note */}
                {(this.props.loanDetails?.rc?.crld || this.props.loanDetails?.rc?.cld || this.props.loanDetails?.rc?.rwvf) && 
                  this.renderReschedule()}
                {/* Render Loan  Conciliation Details */}
                {this.props.loanDetails?.cdd &&
                  this.renderConciliationDetails()}
                {this.props.loanDetails?.ocld &&
                  this.props.loanDetails?.ncld &&
                  this.renderConciliationHistory()}

                {this.props.loanDetails !== null && (
                  <Row className='pt-3 underlineText d-flex align-items-center'>
                    {Strings("Info")}
                    {LOAN_DETAILS_INFO_ACCESS.includes(
                      this.props.logInUserInfo.role
                    ) && this.props.loanDetails.info.appfq !==
                      FREQUENCIES.WEEKLY_SIX &&
                      this.props.loanDetails.info.st ===
                      "DISBURSED" && (
                        <div className='pencil mx-1 p-1'>
                          <img
                            src={editNote}
                            className={"editIcon m-0"}
                            alt={"edit"}
                            title={Strings("EditableFileds")}
                            onClick={() => this.showEditableFields(3)}
                          />
                        </div>
                      )}
                  </Row>
                )}
                <Row className='alignVertical'>
                  {this.renderLoanKeyMapping()}
                </Row>
                {this.props.loanDetails?.info?.aepi && (
                  <>
                    <Row className='pt-3 underlineText'>
                      {Strings("administrativeExpense")}
                    </Row>
                    <Row className='alignVertical w-100'>
                      {this.renderAdministrativeExpenses()}
                    </Row>
                  </>
                )}
                {this.renderDownpaymentCollectionSection()}
                {this.props.loanDetails !== null && (
                  <Row className='pt-3 underlineText d-flex align-items-center'>
                    {Strings("State")}
                    {LOAN_DETAILS_INFO_ACCESS.includes(
                      this.props.logInUserInfo.role
                    ) &&
                      this.props.loanDetails.info.st ===
                      "DISBURSED" && (
                        <div className='pencil mx-1 p-1'>
                          <img
                            src={editNote}
                            className={"editIcon m-0"}
                            alt={"edit"}
                            title={Strings("EditableFileds")}
                            onClick={() => this.showEditableFields(1)}
                          />
                        </div>
                      )}
                  </Row>
                )}
                <Row className='alignVertical'>
                  {this.renderLoanStateMapping()}
                </Row>
                {this.props.loanDetails?.provision &&
                  this.renderProvisionDetails && (
                    <Row className='pt-3 underlineText'>
                      {Strings("Provision")}
                    </Row>
                  )}
                {this.props.loanDetails?.provision && (
                  <Row className='alignVertical w-100'>
                    {this.renderProvisionDetails()}
                  </Row>
                )}

                <Row className='underlineText d-flex align-items-center pt-2'>
                  {Strings("Minimart")}
                  {MINIMART_DETAILS_INFO_ACCESS.includes(
                    this.props.logInUserInfo.role
                  ) &&
                    this.props.loanDetails.info.st ===
                    "DISBURSED" && (
                      <div className='pencil mx-1 p-1'>
                        <img
                          src={editNote}
                          className={"editIcon m-0"}
                          alt={"edit"}
                          title={Strings("EditableFileds")}
                          onClick={() => this.showEditableFields(2)}
                        />
                      </div>
                    )}
                </Row>
                <Row className='alignVertical'>
                  {this.renderMinimartDetailsMapping()}
                </Row>
                {(this.props.loanType === LOAN_TYPES.EXTERNAL_FACTORING)
                  && this.renderSupplierDetails()}
                {(this.props.loanType === LOAN_TYPES.FAST_CASH)
                  && this.renderBankDetails()}
                {this.renderAssociation()}
                {this.renderPromotions()}
              </Row>
            </Col>
          ) : (
            <Col
              md={10}
              className=' pl-5 justify-center display-flex noRecordsFound'
            >
              <span>{Strings("NoRecordsFound")}</span>
            </Col>
          )}
        </Row>
      </Collapse>
    );
  };

  renderLoanAmountModal = () => {
    return (
      <LoanAmountModal
        open={this.state.showLoanAmountModal}
        onModalClose={() =>
          this.setState({
            showLoanAmountModal: false,
            showLoanAmountModalTitle: "",
          })
        }
        modalTitle={this.state.showLoanAmountModalTitle}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        loanAmountDivisionData={this.state.loanAmountDivisionData}
        loanAmountTotalSum={this.state.loanAmountTotalSum}
        isLoanDetails={true}
      />
    );
  };

  render() {
    return (
      <div className='loanDetails container-fluid'>
        {this.props.notesModal && this.renderNotesModal()}
        {this.props.loanDetails &&
          !this.props.loanDetails.provision &&
          this.props.loanDetails.info.st === "DISBURSED" && (
            <span className='warningText'>
              {Strings("PROVISION_SCHEME_ERROR")}
            </span>
          )}
        {this.state.showLoanAmountModal &&
          this.renderLoanAmountModal()}
        {this.state.showModal && this.renderEditableModal()}
        {this.state.showConciliationdetailsModal && this.renderConciliationDetailsModal()}
        {this.state.showRestructureDetailsModal&&this.renderRestructureDetails()}
        <Row className='pl-4 pb-3'>{this.renderHeadingRow()}</Row>
        {this.renderCollapse()}
        {this.renderCollapseView()}
        {this.props.loanDetails && this.renderLoanDetailsButton()}

        {this.renderAmortizationTable()}
      </div>
    );
  }
}

export default LoanDetails;
